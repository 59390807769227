import {
  DataGrid,
  GridToolbar,
  filterableGridColumnsSelector,
} from "@material-ui/data-grid";
import { configapp, stis } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import EditSharpIcon from "@mui/icons-material/EditSharp";
// import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Tooltip } from "@mui/material";
import { getTestOfNewDocuments } from "../function";
import Badge from "@mui/material/Badge";
import React, { useRef, useState } from "react";
import { TextField, Box, CircularProgress } from "@mui/material";
import { Button, Stack } from "react-bootstrap";
import { SolidButton } from "../../Reuse/SolidButton";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadIcon from "@mui/icons-material/Upload";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
  // documentId,
} from "firebase/firestore";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { Typography } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SoundOnIcon from "@mui/icons-material/VolumeUp";
import SoundOffIcon from "@mui/icons-material/VolumeOff";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PostsTable from "./Post";

const Discounts = (props) => {
  const mediaInputRef = useRef(null);
  const logoInputRef = useRef(null);

  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState([]);
  const [mediaPath, setMediaPath] = useState("");
  const [logoPath, setLogoPath] = useState("");

  const [description, setDescription] = useState("");
  const [discounts, setDiscounts] = useState("0");
  const [isUploading, setIsUploading] = useState(false);
  const [mediaSequence, setMediaSequence] = React.useState([]);
  const [logoSequence, setLogoSequence] = React.useState([]);
  const [name, setName] = useState("Brand");
  const [address, setAddress] = useState("Branch");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [fileUrls, setFileUrls] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [logoUrl, setLogoUrl] = useState(null);
  const [showPost, setShowPost] = useState(true);
  const [showCms, setShowCms] = useState(false);
  const [doId, setDoId] = useState("");

  const info = JSON.parse(localStorage.getItem("currentUser"));

  const showAllPost = () => {
    setShowPost(true);
  };
  const hideAllPost = () => {
    setShowPost(false);
  };

  const showCMS = () => {
    setShowCms(true);
    setShowPost(false);
  };
  const hideCMS = () => {
    setShowCms(false);
  };

  const listStyle = {
    display: "inline-block",
    marginRight: "40px",
    marginLeft: "-40px",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const handleMediaInputChange = async () => {
    const files = mediaInputRef.current.files;

    if (files && files.length > 0 && fileUrl.length < 1) {
      const selectedMedia = [...files];
      const mediaNames = selectedMedia.map((file) => file.name);
      const newFileUrls = [...fileUrl];

      setFileUrl([...fileUrl, ...mediaNames]);

      const mediaType = selectedMedia[0].type.startsWith("image")
        ? "image"
        : "video";
      const filesUrls = selectedMedia.map((file) => URL.createObjectURL(file));

      newFileUrls.push(...filesUrls);

      setMediaSequence((prevSequence) => [
        ...prevSequence,
        ...mediaNames.map((name) => ({ type: mediaType, path: name })),
      ]);
      setFileUrl(newFileUrls);
    } else {
      notifyError("You cannot upload more than 1 media file.");
    }
  };

  const handleLogoInputChange = async () => {
    const files = logoInputRef.current.files;
    if (files && files.length > 0 && uploadedLogo.length < 1) {
      const selectedLogo = files[0];
      const logoNames = [...uploadedLogo, selectedLogo.name];
      setUploadedLogo(logoNames);
      setLogoPath(selectedLogo.name);
      const logoType = selectedLogo.type.startsWith("image")
        ? "image"
        : "video";

      // Upload the logo file and get the URL
      const logoUrl = await uploadFile(selectedLogo, "logo");
      setLogoUrl(logoUrl); // Set a single URL instead of an array

      setLogoSequence((prevSequence) => [
        ...prevSequence,
        { type: logoType, path: selectedLogo.name },
      ]);
    } else {
      notifyError("You cannot upload more than 1 logo file.");
    }
  };
  const handleRemoveMedia = (index) => {
    const updatedMedia = [...fileUrl];
    updatedMedia.splice(index, 1);
    setFileUrl(updatedMedia);

    const updatedSequence = [...mediaSequence];
    updatedSequence.splice(index, 1);
    setMediaSequence(updatedSequence);
    setMediaPath("");
  };
  const sendPushNotification = async (userToken, documId) => {
    try {
      const message = {
        notification: {
          title: "INVOSEG",
          body: "INVOSEG added new Discounts",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",
        },
        data: {
          documentId: documId,
        },
        // data: {
        //     image: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",
        //   },

        to: userToken,
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("Push notification sent successfully", message);
        reload();
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  const db = configapp.firestore();

  const fetchAllAndSendNotifications = async (documentId, documId) => {
    try {
      console.log("first,", documId);
      const collection = db.collection("UserRequest");

      // Listen for real-time updates to the collection
      collection.onSnapshot((snapshot) => {
        if (snapshot.size === 0) {
          notifyError();
        } else {
          snapshot.forEach((doc) => {
            const userToken = doc.data().FCMtoken;
            if (userToken) {
              console.log("document Id , ", documId);
              sendPushNotification(userToken, documentId);
              console.log("push");
            }
          });
        }
      });
    } catch (error) {
      console.error(
        "Error fetching FCM tokens and sending push notifications:",
        error
      );
    }
  };

  const uploadFile = async (file, fileType) => {
    const storageRef = ref(stis, `${fileType}/${file.name}`);
    await uploadBytes(storageRef, file);

    const downloadUrl = await getDownloadURL(storageRef);

    // console.log(`Uploaded ${fileType}: ${file.name}`);
    return downloadUrl;
  };

  const handleUploadClick = async () => {
    if (
      fileUrl.length > 1 ||
      fileUrl.length === 0 ||
      name === "Brand" ||
      address === "Branch" ||
      selectedCategory === "Select Category" ||
      description === "" ||
      discounts === 0
    ) {
      notifyError("Please fill all the required fields");
      return;
    }
    if (
      isNaN(discounts) ||
      discounts < 5 ||
      discounts > 90 ||
      discounts % 5 !== 0
    ) {
      notifyError(
        "Discount must be a valid number between 5 and 90 and a multiple of 5."
      );
      return;
    }

    setIsUploading(true);

    try {
      const fileUrlUrls = await Promise.all(
        fileUrl.map(async (mediaName) => {
          const mediaUrl = await uploadFile(
            mediaInputRef.current.files[fileUrl.indexOf(mediaName)],
            "media"
          );

          return mediaUrl;
        })
      );

      const db = getFirestore(configapp);
      const feedCollectionRef = collection(db, "discounts");

      const docRef = await addDoc(feedCollectionRef, {
        description: description,
        address: address,
        Name: name,
        discount: discounts,
        logo: { logoUrl },
        category: selectedCategory,
        mediaUrls: fileUrlUrls,
        timestamp: Timestamp.fromDate(new Date()),
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        title: info.societyName,

        societyName: info.societyName,
      });

      const documentId = docRef.id;

      if (documentId) {
        const feedDocRef = doc(feedCollectionRef, documentId);

        await updateDoc(feedDocRef, {
          id: documentId,
        });
        var documId = documentId;
        console.log(documId, "asasas");
        fetchAllAndSendNotifications(documentId, documId);
      }

      console.log(documentId, "oka ", documId, "lllllll");
    } catch (error) {
      notifyError("Error uploading files or saving data to Firestore.");
      // console.error(error);
      // setIsUploading(false);
    }
  };

  const time = new Date().toLocaleTimeString();
  const reload = async () => {
    await window.location.reload();
  };
  return (
    <AdminDashboard>
      <>
        {showCms && (
          <div
            style={{
              display: "flex",
              width: "100%",
              //  backgroundColor:"pink",
              height: "465px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "170px",
                marginTop: "5px",
                flex: 1,
                paddingRight: "20px",
              }}
            >
              <Box>
                <Stack>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Brand Name"
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Branch or Outlet"
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <label style={{ width: "50%" }}>
                    <input
                      ref={logoInputRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleLogoInputChange}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Upload Logo"
                        style={{ width: "300px", marginBottom: "10px" }}
                        onClick={() => logoInputRef.current.click()}
                        value={logoPath}
                        disabled
                      />
                      <div>
                        <label>
                          <WebAssetIcon
                            label="Browse Logo"
                            fontSize="large"
                            cursor="pointer"
                            onClick={() => logoInputRef.current.click()}
                          />
                        </label>
                      </div>
                    </div>
                  </label>
                  <label style={{ width: "50%" }}>
                    <input
                      ref={mediaInputRef}
                      type="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={handleMediaInputChange}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Upload Media"
                        style={{ width: "300px", marginBottom: "10px" }}
                        onClick={() => mediaInputRef.current.click()}
                        value={mediaPath}
                        disabled
                      />
                      <div>
                        <label style={{ width: "50%" }}>
                          <WebAssetIcon
                            label="Browse Media"
                            fontSize="large"
                            cursor="pointer"
                            onClick={() => mediaInputRef.current.click()}
                          />
                        </label>
                      </div>
                    </div>
                  </label>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter your Description"
                    maxRows={5}
                    multiline
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Discount Range 5%- 90%"
                    type="number"
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={discounts}
                    onChange={(e) => setDiscounts(e.target.value)}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "/^-?d+(?:.d+)?$/g",
                    }}
                  ></TextField>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    onChange={(event) =>
                      setSelectedCategory(event.target.value)
                    }
                    style={{ width: "180px", marginBottom: "10px" }}
                  >
                    <MenuItem value="Select Category">Select Category</MenuItem>
                    <MenuItem value="Health">Health</MenuItem>
                    <MenuItem value="Fitness">Fitness</MenuItem>
                    <MenuItem value="Education">Education</MenuItem>
                    <MenuItem value="Food">Food</MenuItem>
                    <MenuItem value="Life Style">Life Style</MenuItem>
                  </Select>
                </Stack>
              </Box>
              <Box>
                {isUploading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    label="Upload"
                    fontSize="large"
                    cursor="pointer"
                    onClick={() => {
                      handleUploadClick();
                      // fetchAllAndSendNotifications();
                    }}
                    style={{ width: "120px" }}
                  >
                    Upload
                  </Button>
                )}
              </Box>
              <Box style={{}}>
                <Typography style={{ marginTop: "20px" }}>
                  <strong>Media Preview:</strong>
                </Typography>
                <ul style={{ marginTop: "10px" }}>
                  {fileUrl.map((filesUrl, index) => (
                    <li key={index} style={listStyle}>
                      {mediaSequence[index].type === "image" && (
                        <img src={filesUrl} height="100px" width="100px" />
                      )}

                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveMedia(index)}
                      />
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
            <div
              style={{ height: "auto", width: "auto", position: "relative" }}
            >
              <img src="iphone.svg" width="100%" height="400px" />
              {/* <div style={{
            position: "absolute",
            top: 9,
            right: 22,
            height: "4.5%",
            width: "13%",
            overflow: "hidden",
            fontSize: "11px",
          }}   >
            <strong>{time}</strong>
          </div> */}
              <div
                style={{
                  position: "absolute",
                  top: 25,
                  left: 8.5,
                  height: "3%",
                  width: "92%",
                  // backgroundColor:"pink"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    height: "100%",
                    width: "15%",
                  }}
                >
                  <img src="arrow.svg" width="100%" height="100%" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: 50,
                    height: "100%",
                    width: "70%",
                    fontSize: "13px",
                    marginTop: "3px",
                  }}
                >
                  <strong>Discounts</strong>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 2,
                    height: "100%",
                    width: "15%",
                  }}
                >
                  <img src="menu.svg" width="100%" height="100%" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 25,
                    height: "100%",
                    width: "15%",
                  }}
                >
                  <img src="bell.svg" width="100%" height="100%" />
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 55,
                  left: 8.5,
                  height: "8%",
                  width: "92%",
                  // backgroundColor:"pink",
                  display: "inline",
                }}
              >
                <button
                  style={{
                    width: "45px",
                    height: "35px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    border: "solid 1px blue",
                    fontSize: "10px",
                    marginLeft: "6px",
                  }}
                >
                  All
                </button>
                <button
                  style={{
                    width: "30px",
                    height: "35px",
                    border: "none",
                    fontSize: "10px",
                    marginLeft: "6px",
                    backgroundColor: "white",
                  }}
                >
                  Health
                </button>
                <button
                  style={{
                    width: "30px",
                    height: "35px",
                    border: "none",
                    fontSize: "10px",
                    marginLeft: "6px",
                    backgroundColor: "white",
                  }}
                >
                  Food
                </button>
                <button
                  style={{
                    width: "45px",
                    height: "35px",
                    border: "none",
                    fontSize: "10px",
                    marginLeft: "6px",
                    backgroundColor: "white",
                  }}
                >
                  Education
                </button>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 100,
                  left: 17,
                  height: "7rem",
                  width: "10rem",
                  padding: "0",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  boxShadow: "5px 0 10px -5px #00000",
                }}
              >
                <Slider {...settings}>
                  {fileUrl.map((filesUrl, index) => (
                    <div key={index}>
                      {mediaSequence[index].type === "image" && (
                        <img src={filesUrl} height="100%" width="100%" />
                      )}

                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 10,
                          width: "35px",
                          height: "30px",
                          objectFit: "cover",
                          // backgroundColor:"pink"
                        }}
                      >
                        {logoUrl && (
                          <img
                            src={logoUrl}
                            height="100%"
                            width="100%"
                            alt="Logo"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 210,
                  left: 17,
                  height: "4rem",
                  width: "10rem",
                  padding: "0",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  backgroundColor: "white",
                  boxShadow: "0 5px 10px -5px #000000",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: 40,
                    left: 10,
                    height: "1rem",
                    width: "auto",
                    padding: "0",
                    fontSize: "11px",
                    // backgroundColor:"yellow"
                  }}
                >
                  <strong>{name}</strong>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left: 10,
                    height: "1.5rem",
                    minWidth: "3rem",
                    maxWidth: "auto",
                    padding: "5px",
                    fontSize: "9px",
                    borderBottomLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    borderTopLeftRadius: "7px",
                    borderBottomRightRadius: "7px",
                    backgroundColor: "#3b1d7d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {address}
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 35,
                    right: 10,
                    height: "1.2rem",
                    minWidth: "1.2rem",
                    maxWidth: "auto",
                    padding: "4px",
                    fontSize: "9px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    backgroundColor: "#3b1d7d",
                    color: "white",
                  }}
                >
                  {`${discounts} %`}
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 7,
                    right: 7,
                    height: "1.2rem",
                    minWidth: "1.2rem",
                    maxWidth: "auto",
                    padding: "4px",
                    fontSize: "9px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    //  color:"white"
                  }}
                ></div>
              </div>
            </div>
            <ToastContainer />
          </div>
        )}
      </>
      <div style={{ marginTop: "60px", marginLeft: "25px" }}>
        {showPost && (
          <>
            <SolidButton
              label="Add Post"
              style={{ cursor: "pointer", marginTop: "-40px" }}
              onClick={showCMS}
            />

            <PostsTable />
          </>
        )}
      </div>
    </AdminDashboard>
  );
};

export default Discounts;
