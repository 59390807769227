import { configapp, stis } from "../../../firebase";
import AdminDashboard from "../Dashboard";

import React, { useRef, useState } from "react";
import { TextField, Box, CircularProgress } from "@mui/material";
import { Stack } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { Typography } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SolidButton } from "../../Reuse/SolidButton";
import PostsTable from "./Post";
const CMS = () => {
  const mediaInputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState([]);
  const [mediaPath, setMediaPath] = useState("");
  const [description, setDescription] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [mediaSequence, setMediaSequence] = React.useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [showPost, setShowPost] = useState(true);
  const [showCms, setShowCms] = useState(false);
  const info = JSON.parse(localStorage.getItem("currentUser"));

  const showAllPost = () => {
    setShowPost(true);
  };
  const hideAllPost = () => {
    setShowPost(false);
  };

  const showCMS = () => {
    setShowCms(true);
    setShowPost(false);
  };
  const hideCMS = () => {
    setShowCms(false);
  };
  const listStyle = {
    display: "inline-block",
    marginRight: "40px",
    marginLeft: "-40px",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {
      setActiveSlide(index);
    },
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const handleMediaInputChange = async () => {
    if (youtubeLink !== "") {
      notifyError(
        "You can only upload either a YouTube link or media at a time."
      );
      return;
    }

    const files = mediaInputRef.current.files;

    if (files && files.length > 0 && fileUrl.length < 5) {
      const selectedMedia = [...files];
      const mediaNames = selectedMedia.map((file) => file.name);
      const newFileUrls = [...fileUrl];

      setFileUrl([...fileUrl, ...mediaNames]);

      const mediaType = selectedMedia[0].type.startsWith("image")
        ? "image"
        : "video";
      const filesUrls = selectedMedia.map((file) => URL.createObjectURL(file));

      newFileUrls.push(...filesUrls);

      setMediaSequence((prevSequence) => [
        ...prevSequence,
        ...mediaNames.map((name) => ({ type: mediaType, path: name })),
      ]);
      setFileUrl(newFileUrls);
    } else {
      notifyError("You cannot upload more than 5 media files.");
    }
  };

  const handleRemoveMedia = (index) => {
    const updatedMedia = [...fileUrl];
    updatedMedia.splice(index, 1);
    setFileUrl(updatedMedia);

    const updatedSequence = [...mediaSequence];
    updatedSequence.splice(index, 1);
    setMediaSequence(updatedSequence);
    setMediaPath("");
  };

  const handleUploadClick = async () => {
    if (
      (youtubeLink !== "" && fileUrl.length > 0) ||
      (youtubeLink === "" && fileUrl.length === 0)
    ) {
      notifyError(
        "Please provide either a YouTube link or upload media, but not both at the same time."
      );
      return;
    }

    if (fileUrl.length > 4) {
      notifyError(
        "Please check the number of uploaded media files or fill in the description."
      );
      return;
    }

    setIsUploading(true);

    try {
      let mediaUrls = [];

      // Upload media files
      if (fileUrl.length > 0) {
        mediaUrls = await Promise.all(
          fileUrl.map(async (mediaName) => {
            const mediaUrl = await uploadFile(
              mediaInputRef.current.files[fileUrl.indexOf(mediaName)],
              "media"
            );

            return mediaUrl;
          })
        );
      }

      // Handle YouTube link separately
      if (youtubeLink !== "") {
        mediaUrls.push(youtubeLink);
      }

      const db = getFirestore(configapp);
      const feedCollectionRef = collection(db, "feed");

      const docRef = await addDoc(feedCollectionRef, {
        title: info.societyName,
        logo: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Logos%2Flogo.jpg?alt=media&token=12ddb74a-dd83-4068-aa91-0c8a55babf49",
        description: description,
        mediaUrls: mediaUrls,
        // fav: false,
        likes: 0,
        youtubeLink: youtubeLink,
        timestamp: Timestamp.fromDate(new Date()),
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),

        Likesuids: [],
        izmir: true,
        ar: true,
        model: true,
        canal: true,
        vertical: true,
        mazgar: true,
        societyName: info.societyName,
      });

      const documentId = docRef.id;

      if (documentId) {
        const feedDocRef = doc(feedCollectionRef, documentId);

        await updateDoc(feedDocRef, {
          title: info.societyName,
          logo: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Logos%2Flogo.jpg?alt=media&token=12ddb74a-dd83-4068-aa91-0c8a55babf49",
          description: description,
          mediaUrls: mediaUrls,
          // fav: false,
          likes: 0,
          youtubeLink: youtubeLink,
          timestamp: Timestamp.fromDate(new Date()),
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          id: documentId,
          Likesuids: [],
          izmir: true,
          ar: true,
          model: true,
          canal: true,
          vertical: true,
          mazgar: true,
        });
      }

      setDescription("");
      setIsUploading(false);
      window.location.reload();
    } catch (error) {
      notifyError("Error uploading files or saving data to Firestore.");
      console.error(error);
      setIsUploading(false);
    }
  };

  const uploadFile = async (file, fileType) => {
    const storageRef = ref(stis, `${fileType}/${file.name}`);
    await uploadBytes(storageRef, file);

    const downloadUrl = await getDownloadURL(storageRef);

    // console.log(`Uploaded ${fileType}: ${file.name}`);
    return downloadUrl;
  };

  const time = new Date().toLocaleTimeString();

  //sound
  const [videoMuteStates, setVideoMuteStates] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);

  const isVideoMuted = (index) => {
    return videoMuteStates[index];
  };

  const toggleVideoSound = (index) => {
    const updatedMuteStates = [...videoMuteStates];
    updatedMuteStates[index] = !updatedMuteStates[index];
    setVideoMuteStates(updatedMuteStates);
  };

  let currentVideo = null;

  const handleSlideChange = (index) => {
    setActiveSlide(index);

    // Pause and reset all videos
    document.querySelectorAll("video").forEach((video, i) => {
      video.pause();
      video.currentTime = 0;
    });

    // Play the video in the active slide
    const activeVideo = document.querySelector(`#video-${index}`);
    if (activeVideo) {
      activeVideo.play();
      currentVideo = activeVideo;
    }

    document.querySelectorAll(".sound-button").forEach((button, i) => {
      button.style.display = i === index ? "block" : "none";
    });
  };

  const handleSoundButtonClick = (index) => {
    const updatedMuteStates = Array(fileUrl.length).fill(true);
    updatedMuteStates[index] = !videoMuteStates[index];
    setVideoMuteStates(updatedMuteStates);

    document.querySelectorAll("video").forEach((video, i) => {
      video.muted = updatedMuteStates[i];
    });
  };

  const sendPushNotification = async (userToken, documentId) => {
    try {
      const message = {
        notification: {
          title: `${info.societyName} added new post`,
          body: description,
          // imageUrl: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",
        },
        data: {
          documentId: documentId,
        },
        // data: {
        //     image: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",
        //   },

        to: userToken,
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("Push ", documentId);

        console.log("Push notification sent successfully", message);
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };
  const db = configapp.firestore();

  const fetchAllAndSendNotifications = async () => {
    try {
      const collection = db.collection("UserRequest");

      // Listen for real-time updates to the collection
      collection.onSnapshot((snapshot) => {
        if (snapshot.size === 0) {
          notifyError();
        } else {
          snapshot.forEach((doc) => {
            const userToken = doc.data().FCMtoken;
            if (userToken) {
              sendPushNotification(userToken);
              console.log(userToken, "kkkk");
            }
          });
        }
      });
    } catch (error) {
      console.error(
        "Error fetching FCM tokens and sending push notifications:",
        error
      );
    }
  };

  return (
    <AdminDashboard>
      <>
        {showCms && (
          <div
            style={{
              display: "flex",
              width: "100%",
              //  backgroundColor:"pink",
              height: "465px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "170px",
                marginTop: "5px",
                flex: 1,
                paddingRight: "20px",
              }}
            >
              <Box>
                <Stack>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Paste your Youtube Link Here"
                    maxRows={2}
                    multiline
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={youtubeLink}
                    onChange={(e) => setYoutubeLink(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter your Description"
                    maxRows={3}
                    multiline
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <label style={{ width: "25%" }}>
                    <input
                      ref={mediaInputRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleMediaInputChange}
                      multiple
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Upload Media"
                        style={{ width: "180px", marginBottom: "10px" }}
                        onClick={() => mediaInputRef.current.click()}
                        value={mediaPath}
                        disabled
                      />
                      <div>
                        <label>
                          <WebAssetIcon
                            label="Browse Media"
                            fontSize="large"
                            cursor="pointer"
                            onClick={() => mediaInputRef.current.click()}
                          />
                        </label>
                      </div>
                    </div>
                  </label>
                </Stack>
              </Box>
              <Box>
                {isUploading ? (
                  <CircularProgress />
                ) : (
                  <SolidButton
                    label="Upload"
                    fontSize="large"
                    cursor="pointer"
                    onClick={() => {
                      handleUploadClick();
                      fetchAllAndSendNotifications();
                    }}
                    style={{ width: "120px" }}
                  />
                )}
              </Box>
            </div>
            <div
              style={{ height: "auto", width: "auto", position: "relative" }}
            >
              <img src="iphone.svg" width="100%" height="400px" />
              <div
                style={{
                  position: "absolute",
                  top: 9,
                  right: 22,
                  height: "4.5%",
                  width: "13%",
                  overflow: "hidden",
                  fontSize: "11px",
                }}
              >
                {/* <strong>{time}</strong> */}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 25,
                  left: 8.5,
                  height: "5.5%",
                  width: "92%",
                  // backgroundColor:"pink"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 5,
                    height: "100%",
                    width: "15%",
                    border: "2px solid grey",
                    borderRadius: "30px",
                    padding: "1px",
                  }}
                >
                  <img src="logo.jpg" width="100%" height="100%" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: 35,
                    height: "100%",
                    width: "70%",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  <strong>{info.societyName}</strong>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 65,
                  left: 8,
                  height: "8.5rem",
                  width: "11.3rem",
                  padding: "0",
                  // backgroundColor:"pink",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
              >
                <Slider {...settings} afterChange={handleSlideChange}>
                  {fileUrl.map((filesUrl, index) => (
                    <li key={index}>
                      {mediaSequence[index].type === "image" && (
                        <img
                          src={filesUrl}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      {mediaSequence[index].type === "video" && (
                        <div
                          style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <video
                            src={filesUrl}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            autoPlay
                            muted={isVideoMuted(index)}
                            id={`video-${index}`}
                          />
                          <div
                            className="sound-button"
                            style={{
                              width: "22px",
                              height: "22px",
                              borderRadius: "50%",
                              backgroundColor: "white",
                              position: "absolute",
                              right: 7,
                              bottom: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              display: index === activeSlide ? "block" : "none",
                              cursor: "pointer",
                              padding: "0px",
                            }}
                            onClick={() => handleSoundButtonClick(index)}
                          >
                            {isVideoMuted(index) ? (
                              <img
                                src="soundOn.svg"
                                style={{ width: "22px", height: "22px" }}
                              />
                            ) : (
                              <img
                                src="soundOff.svg"
                                style={{ width: "22px", height: "22px" }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </Slider>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 210,
                  left: 8,
                  height: "6%",
                  width: "92%",
                }}
              >
                <ul style={{ listStyleType: "none" }}>
                  <li
                    style={{
                      display: "inline",
                      marginLeft: "-25px",
                      marginRight: "10px",
                    }}
                  >
                    <img src="heart.png" width="15px" height="15px" />
                  </li>
                  <li style={{ display: "inline" }}>
                    <img src="chat.png" width="20px" height="20" />
                  </li>
                </ul>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 235,
                  left: 10,
                  height: "30%",
                  width: "11rem",
                  paddingLeft: "5px",
                  paddingTop: "5px",
                  fontSize: "10px",
                  overflowY: "auto",
                  textOverflow: "ellipsis",
                  // backgroundColor:"pink"
                }}
              >
                <strong>{info.societyName}:</strong>
                {description}
              </div>
            </div>
            <ToastContainer />
          </div>
        )}
        <div
          style={{
            height: "9rem",
            width: "44rem",
            marginTop: "-220px",
            // backgroundColor:"red"
          }}
        >
          <Box style={{ marginTop: "40px" }}>
            <Typography style={{ marginTop: "20px" }}>
              <strong>Media Preview:</strong>
            </Typography>
            <ul style={{ marginTop: "10px" }}>
              {fileUrl.map((filesUrl, index) => (
                <li key={index} style={listStyle}>
                  {mediaSequence[index].type === "image" && (
                    <img src={filesUrl} height="100px" width="100px" />
                  )}
                  {mediaSequence[index].type === "video" && (
                    <video
                      src={filesUrl}
                      height="150px"
                      width="150px"
                      muted
                      autoPlay
                    />
                  )}
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveMedia(index)}
                  />
                </li>
              ))}
            </ul>
          </Box>
        </div>
      </>
      <div></div>
      <div style={{ marginTop: "160px", marginLeft: "25px" }}>
        {showPost && (
          <>
            <SolidButton
              label="Add Post"
              style={{ cursor: "pointer", marginTop: "-40px" }}
              onClick={showCMS}
            />
            <PostsTable />
          </>
        )}
      </div>
    </AdminDashboard>
  );
};

export default CMS;
