import React, { useState, useEffect } from "react";
import { Drawer, Stack, Box, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Card } from "@material-ui/core";
import CardContent from '@mui/material/CardContent';
import { useSelector } from "react-redux";

const ContentView = ({ close, open }) => {
  const completedDoctor = useSelector(
    (state) => state.user.completedDoctorData
  );

  return (
    <Drawer
      sx={{
        display: { sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          overflowX: "hidden",
          width: "40%",
          position: "fixed",
          zIndex: "990",
          borderRadius: "10px",
          boxShadow: 3,
        },
      }}
      anchor="right"
      open={open}
      onClose={close}
    >
      <Box sx={{ padding: "20px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "3rem" }}
        >
          <div style={{ background: "#0f277f", color: "white", width: "140%" }}>
            <Typography
              variant="p"
              sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Rooboto" }}
            >
              Prescription:
            </Typography>
            <Box
              sx={{ cursor: "pointer", float: "right", marginTop: "5px" }}
              onClick={() => close(false)}
            >
              <CloseIcon />
            </Box>
          </div>
        </Stack>

        <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <Card
            sx={{ minWidth: 275, padding: "20px" }}
            style={{
              color: "black",
            }}
          >
            <CardContent>
              <Typography sx={{}} color="black" gutterBottom variant="h6">
                Medicine:
              </Typography>
              <Typography variant="body1">
                {" "}
                {completedDoctor?.patient?.medicine}
              </Typography>
              <Typography sx={{ mb: 1 }} color="black" variant="h6">
                Test Needed:
              </Typography>
              <Typography variant="body1">
                {" "}
                {completedDoctor?.patient?.testNeeded}
              </Typography>
              <Typography sx={{ mt: 1 }} color="black" variant="h6">
                Things To Do:
              </Typography>
              <Typography variant="body1">
                {" "}
                {completedDoctor?.patient?.thingsToDo}
              </Typography>
              <Typography sx={{ mt: 1 }} color="black" variant="h6">
                Disease:
              </Typography>
              <Typography variant="body1">
                {" "}
                {completedDoctor?.patient?.disease}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Drawer>
  );
};

function AddViewDrawer({ open, close }) {
  const [isLoading, setIsLoading] = useState(true);
  const completedDoctor = useSelector((state) => state.user.completedDoctorData);

  useEffect(() => {
    if (completedDoctor) {
      setIsLoading(false);
    }
  }, [completedDoctor]);

 return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
            boxShadow:3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        {isLoading ? (
          <Box sx={{ display: "flex",
           justifyContent: "center",
            alignItems: "center",
             height: "100%"
              }}>
            <CircularProgress />
          </Box>
        ) : (
          <ContentView
          open={open} close={close}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
}

export default AddViewDrawer;
