import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import AddComplaintsDrawer from "./AddComplaintsDrawer";
import ComplaintNoti from "./ComplaintNoti";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Tooltip } from "@mui/material";
import { getTestOfNewDocuments } from "../function";
import Badge from '@mui/material/Badge';
import { useDispatch } from "react-redux";
import { setComplainData } from "../../store/slices/Userslice";
import axios from "axios";
import { socket } from "../../../socket";


const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: 'purple', // Change this to your desired color
    fontWeight: '1800',
    borderRight: 'none',
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: 'none',
  },
}));

const CustomHeader = (props) => {
  return <div style={{ textAlign: 'center' }}>{props.label}</div>;
};



const Complaints = (props) => {
  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);
  const [rowData, setRowData] = useState([]);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();
  const [showComplaintNoti, setShowComplaintNoti] = useState(false);
  const [countComp, setCountComp] = useState(null);


  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    fetchDataApi()
  }, [tempData]);

  useEffect(() => {
    socket.on('receive-complain', (data) => {
      if (data) {
        console.log(data)
        setTempData([data])
      }
    });
  }, []);

  const fetchDataApi = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/complain`)
    const data = response.data.map(({ userId, ...rest }) => ({ ...rest, uid: rest?.uid?._id, id: rest?._id }))
    setRowData(data)
    setTempData([])
  }


  const fetchCountComp = async () => {
    try {
      const count = await getTestOfNewDocuments("complian");
      return count;
    } catch (error) {
      console.error("Error getting false documents:", error);
      return 0;
    }
  };

  const countCompPromise = fetchCountComp();

  countCompPromise.then((countData) => {
    setCountComp(countData)
  });


  useEffect(() => {
  }, [

    countComp,
  ]);


  const handleComplaintsEdit = (row) => {
    setOpenEdit(true);
    dispatch(setComplainData(row));


  };
  const handleComplaintsNoti = () => {
    setShowComplaintNoti(!showComplaintNoti);
  };


  const editColumn = {
    field: "Action",
    headerName: "Action",
    headerClassName: classes.headerCell,
    width: 120,
    renderCell: (cellData) => (
      <ContentPasteIcon
        style={{
          margin: "0 10px",
          cursor: "pointer",
        }}
        onClick={(e) => handleComplaintsEdit(cellData.row)}
      >
        Action
      </ContentPasteIcon>

    ),
  };


  return (

    <AdminDashboard>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          className={classes.roots}
          style={{ width: "100%", borderRadius: "15px", padding: "10px", background: "white", boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)", marginTop: "50px", marginLeft: "-15px" }}
          columns={[
            editColumn,
            { field: "name", width: 180, headerName: "Name", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "phoneNo", width: 180, headerName: "Phone No", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "address", width: 200, headerName: "Address", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "email", width: 200, headerName: "Email Address", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fname", width: 200, headerName: "Father Name", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fphoneNo", width: 200, headerName: "Father Phone#", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmName", width: 200, headerName: "Family Member Name ", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmphoneNo", width: 200, headerName: "Family Member Phone#", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmName1", width: 200, headerName: "Family Member Name ", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmphoneNo1", width: 200, headerName: "Family Member Phone#", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },

          ]
          }
          rows={rowData.filter((vv) => vv.edit === false && vv.societyName === info.societyName)}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: 'white',
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",// Set your desired background color here
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: 'center',
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "600px",
                  marginLeft: "150px",
                  height: "35px",
                  marginTop: "-60px"
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),

          }}
          getRowClassName={rowClassName(classes)}

        />
      </div>
      <AddComplaintsDrawer
        open={openEdit}
        close={setOpenEdit}
        reCall={()=>fetchDataApi()}

      />
      {showComplaintNoti && (
        <ComplaintNoti
          open={showComplaintNoti}
          close={() => setShowComplaintNoti(false)}
        />
      )}
      <Tooltip title={`New Requests ${countComp}`} placement="left">
        <IconButton
          aria-label={countComp}
          style={{
            position: "absolute",
            top: "25px",
            right: "45px",
            zIndex: 1100,
          }}
          onClick={() => {
            handleComplaintsNoti()
          }}
        >
          <Badge badgeContent={countComp} color="secondary">

            <NotificationsNoneIcon
              style={{
                fontSize: 35,
              }}
            />
          </Badge>

        </IconButton>
      </Tooltip>
    </AdminDashboard>
  );
};
export default Complaints;
