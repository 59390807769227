import {  Container, Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "../Reuse/LoginForm";
import LoginLogo from "../Reuse/LoginLogo";

// Make By Shoaib
const useStyles = makeStyles({
  root: {
    background: 'rgb(249, 250, 251)',
    height: '100vh',
    display: 'grid',
    placeItems: 'center',
  },
  heading: {
    textAlign: 'center',
  },
  content: {
    maxWidth: 480,
    padding: 25,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#fff',
  },
});

export const Login = (props) => {
  const classes = useStyles();

  return (
    <>
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <Box className={classes.content}>
          <Box className={classes.heading} >
            <LoginLogo/>
            <Typography style={{ color: "black", marginBottom:"10px",  }}>
              Login to your account
            </Typography>
          </Box>

          <LoginForm {...props} />

        </Box>
      </Container>
    </Box>
    </>
  );
};
