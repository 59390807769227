import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import AddDoctorDrawerEdit from "../Doctor/AddDoctorDrawerEdit";
import { IconButton, Tooltip } from "@mui/material";
import { Badge } from "@mui/material";
import { setDoctorData } from "../../store/slices/Userslice";
import { useDispatch } from "react-redux";
import AddDoctorNoti from "./AddDoctorNoti";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { getTestOfNewDocuments } from "../function";
import { configapp } from "../../../firebase";

const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: "purple",
    fontWeight: "1800",
    borderRight: "none",
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: "none",
  },
}));

const Doctor = (props) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [showDoctorNoti, setShowDoctorNoti] = useState(false);
  const [countDoc, setCountDoc] = useState(null);
  const [rowData, setRowData] = useState([]); 

  const dispatch = useDispatch();
  const info = JSON.parse(localStorage.getItem("currentUser"));

  useEffect(() => {
    const unsubscribe = configapp
      .firestore()
      .collection(info.SubRole) 
      .orderBy("pressedTime", "desc")
      .limit(30)
      .onSnapshot((snapshot) => {
        const data = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            pressedTime: new Date(doc.data()["pressedTime"].seconds * 1000),
            id: doc.id,
          }))
          .filter(
            (val) => val.edit === false && val.societyName === info.societyName
          );

        setRowData(data); 
      });

    return () => {
      unsubscribe();
    };
  }, [info.SubRole]);

  const handleDoctorEdit = (row) => {
    setOpenEdit(true);
    dispatch(setDoctorData(row));
  };

  const handleDoctorNoti = () => {
    setShowDoctorNoti(!showDoctorNoti);
  };

  const fetchCountDoc = async () => {
    try {
      const count = await getTestOfNewDocuments("button-two");
      return count;
    } catch (error) {
      console.error("Error getting false documents:", error);
      return 0;
    }
  };

  const countDocPromise = fetchCountDoc();

  countDocPromise.then((countData) => {
    setCountDoc(countData);
  });

  useEffect(() => {}, [countDoc]);

  return (
    <AdminDashboard>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          className={classes.roots}
          style={{
            width: "100%",
            borderRadius: "15px",
            padding: "10px",
            background: "white",
            boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
            marginTop: "50px",
            marginLeft: "-15px",
          }}
          columns={[
            {
              field: "Action",
              width: 120,
              renderCell: (cellData) => (
                <EditSharpIcon
                  style={{ margin: "0 10px", cursor: "pointer" }}
                  onClick={() => handleDoctorEdit(cellData.row)}
                >
                  Action
                </EditSharpIcon>
              ),
            },
            { field: "name", headerName: "Name", width: 180 },
            { field: "phoneNo", headerName: "Phone No", width: 180 },
            { field: "address", headerName: "Address", width: 200 },
            { field: "age", width: 100 },
            { field: "email", headerName: "Email Address", width: 200 },
            { field: "fname", width: 250, headerName: "Father name" },
            {
              field: "fPhoneNo",
              width: 250,
              headerName: "Father Phone number",
            },
            { field: "fmName", headerName: "FMember Name", width: 250 },
            {
              field: "fmphoneNo",
              headerName: "Family Member Phone#",
              width: 250,
            },
            { field: "fmName1", headerName: "FMember Name", width: 250 },
            {
              field: "fmphoneNo1",
              headerName: "Family Member Phone#",
              width: 250,
            },
          ]}
          rows={rowData}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "white",
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "600px",
                  marginLeft: "150px",
                  height: "35px",
                  marginTop: "-60px",
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),
          }}
          getRowClassName={rowClassName(classes)}
        />
      </div>

      <AddDoctorDrawerEdit open={openEdit} close={() => setOpenEdit(false)} />
      {showDoctorNoti && (
        <AddDoctorNoti
          open={showDoctorNoti}
          close={() => setShowDoctorNoti(false)}
        />
      )}
      <Tooltip title={`New Requests ${countDoc}`} placement="left">
        <IconButton
          ariel-label={countDoc}
          style={{
            position: "absolute",
            top: "25px",
            right: "45px",
            zIndex: 1100,
          }}
          onClick={() => {
            handleDoctorNoti();
          }}
        >
          <Badge badgeContent={countDoc} color="secondary">
            <NotificationsNoneIcon
              style={{
                fontSize: 35,
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
    </AdminDashboard>
  );
};

export default Doctor;
