import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { configapp } from "../../../firebase"; // Import your Firebase config here
import AdminDashboard from "../Dashboard";

const Residence = (props) => {
  const [textValue, setTextValue] = useState("");
  const [residentData, setResidentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const db = configapp.firestore();

 const handleFind = async () => {
   setIsLoading(true);
   try {
     const residentID = textValue.trim();
     const collection = db.collection("UserRequest");
     const unsubscribe = collection
       .where("residentID", "==", residentID)
       .onSnapshot(
         (querySnapshot) => {
           if (querySnapshot.size === 0) {
             setResidentData(null);
             alert("No matching document found.");
           } else {
             const documentData = querySnapshot.docs[0].data();
             const selectedFields = {
               Name: documentData.Name,
               Address: documentData.address,
               Phone_Number: documentData.Phoneno,
               Age: documentData.age,
               ResidentID: documentData.residentID,
               Father_Name: documentData.fName,
               Father_Phone_Number: documentData.fPhonenumber,
             };
             setResidentData(selectedFields);
           }
           setIsLoading(false);
         },
         (error) => {
           console.error("Error searching for resident data:", error);
           alert("An error occurred while searching for resident data.");
           setIsLoading(false);
         }
       );

     // Return the unsubscribe function in case you need to unsubscribe later
     return () => unsubscribe();
   } catch (error) {
     console.error("Error searching for resident data:", error);
     alert("An error occurred while searching for resident data.");
     setIsLoading(false);
   }
 };


  return (
    <AdminDashboard>
      <TextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        placeholder="Enter Resident ID"
      />

      <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
        <Button
          onClick={handleFind}
          style={{
            width: "90px",
            color: "white",
            backgroundColor: "black",
            borderRadius: "10px",
            textAlign: "center",
          }}
          disabled={isLoading}
        >
          Verify
        </Button>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : residentData ? (
        <div>
          <h2>Resident Data</h2>
          <pre>{JSON.stringify(residentData, null, 2)}</pre>
        </div>
      ) : null}
    </AdminDashboard>
  );
};

export default Residence;
