import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { configapp } from "../../../firebase";
import AdminDashboard from "../Dashboard";

const Logo = (props) => {
  const [textValue, setTextValue] = useState("");
  const db = configapp.firestore();

  const handleUploadClick = () => {
    const documentIdToUpdate = 'JBaNxlyYfAseFOcmcw04'; 

    const fieldToUpdate = textValue;

    db.collection("Logo").doc(documentIdToUpdate)
      .update({
        logoId: fieldToUpdate,
      })
      .then(() => {
        setTextValue("");
        console.log("Document successfully updated!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  return (
    <AdminDashboard>
      <TextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
        <Button
          onClick={() => {
            handleUploadClick();
          }}
          style={{
            width: "90px",
            color: "white",
            backgroundColor: "black",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          Upload
        </Button>
      </div>
    </AdminDashboard>
  );
};

export default Logo;
