
import React, {useState} from "react";
import { configapp } from "../../../firebase";
import AdminDashboard from "../Dashboard";

import { TextField} from "@mui/material";
import { Button } from "react-bootstrap";


const VideoPanel = (props) => {
    const [textValue, setTextValue] = useState(""); 
    const db = configapp.firestore(); 
  
    const handleUploadClick = () => {
      db.collection("VideoPanel") 
      // .limit(30)

        .add({
          Url: textValue,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          timestamp:new Date()
        })
        .then(() => {
          // console.log("Document successfully written!");
          setTextValue("");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    
      // setIsLoading(true);
    
      // setTimeout(() => {
      //   setIsLoading(false);
    
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 1000);
      // }, 1000);
    };
    


  return (
      
    <AdminDashboard>
  
  <TextField
  value={textValue}
  onChange={(e) => setTextValue(e.target.value)}
  />
   <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
        <Button
         onClick={() => {
          handleUploadClick();
          
        }}
        
          style={{
            width: "90px",
            color: "white",
            backgroundColor: "black",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          Upload
        </Button>
      </div>

      

     </AdminDashboard>
);
};
export default VideoPanel;
