import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import AddViewDrawer from "./AddViewDrawer";
import { setCompletedDoctorData } from "../../store/slices/Userslice";
import { useDispatch } from "react-redux";
import { configapp } from "../../../firebase";


const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: 'purple', 
    fontWeight: '1800',
    borderRight: 'none', 
    fontSize:"16px",
    textAlign:"center",
  },
  rowCell: {
    borderBottom: 'none', 
  },
}));




const CompletedDoctor = (props) => {
  const classes = useStyles();
  const [openView, setOpenView] = useState(false);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch()

 useEffect(() => {
   const unsubscribe = configapp
     .firestore()
     .collection("consultation")
     .orderBy("pressedTime", "desc")
     .limit(30)
     .onSnapshot((snapshot) => {
       const data = snapshot.docs
         .map((doc) => ({
           ...doc.data(),
           pressedTime: new Date(doc.data()["pressedTime"].seconds * 1000),
           id: doc.id,
         }))
         .filter(
           (val) =>
             val.prescription === true && val.societyName === info.societyName
         );

       setRowData(data);
     });

   return () => {
     unsubscribe();
   };
 }, [info.SubRole]);

  const handleResponseView = (row) => {
    setOpenView(true);
    dispatch(setCompletedDoctorData(row));
  };

 const viewColumn = {
    field: "View",
    width: 120,
    renderCell: (cellData) => (
      <RemoveRedEyeIcon
        style={{ margin: "0 10px", cursor: "pointer" }}
        onClick={(e) => handleResponseView(cellData.row)}
      >
        Action
      </RemoveRedEyeIcon>
    ),
  };
 
  return (
    <>
      <AdminDashboard>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            className={classes.roots}
            style={{ width: "100%" , borderRadius:"15px",padding:"10px", background:"white", boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)", marginTop:"50px",marginLeft:"-15px" }}
            columns={[
               viewColumn,
    { field: 'patientName', headerName: ' Name',  valueGetter: (params ) =>  params.row?.doctor?.patientName, width: 200},
    { field: 'patientPhone', headerName: ' Phone',  valueGetter: (params ) =>  params.row?.doctor?.patientPhone, width: 200},
    { field: 'patientAddress', headerName: ' Address',  valueGetter: (params ) =>  params.row?.doctor?.patientAddress, width: 250},
    { field: 'patientEmail', headerName: ' Email',  valueGetter: (params ) =>  params.row?.doctor?.patientEmail, width: 250},
    { field: "Fname", headerName: "Father Name", width: 200 },
    { field: "Fphone", headerName: "Father Phone", width: 200 },
    { field: "FMname", headerName: "Family Member Name", width: 200 },
    { field: "FMphone", headerName: "Family Member Phone", width: 200 },
    { field: "FMname1", headerName: "Family Member Name", width: 200 },
    { field: "FMphone1", headerName: "Family Member Phone", width: 200 },

]}
            rows={rowData}
            pagination
          pageSize={10} 
          rowsPerPageOptions={[10]} 
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    backgroundColor: 'white', 
                    border:"black",
                    boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                    marginBottom: "20px",
                    display:"flex",
                    justifyContent:'center',
                    textAlign:"center",
                    alignItems:"center",
                    alignContent:"center",
                    alignSelf:"center",
                    padding:"10px",
                    borderRadius:"20px",
                    width:"600px",
                    marginLeft:"150px",
                    height:"35px",
                    marginTop:"-60px"
                  }}
                >
                  <GridToolbar {...props} />
                </div>
              ),
          
            }}
            getRowClassName={rowClassName(classes)}
  
          />
        </div>

        <AddViewDrawer
          open={openView}
         
          close={() => setOpenView(false)}
        />
       
      </AdminDashboard>
    </>
  );
};

export default CompletedDoctor;
