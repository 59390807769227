import React, { useState, useEffect } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../../firebase";
import { oldDocuments,getCountOfNewDocuments } from "../function";

const NotHomeNoti = ({ open, close ,setNewDocumentCountNotHome}) => {
    
  const [securityData, setNotHomeData] = useState([]);
  const [newDocumentsCountNotHome, setNewDocumentsCountNotHome] = useState("");
  const info = JSON.parse(localStorage.getItem("currentUser"));


  useEffect(() => {
    // Get false document counts
    getCountOfNewDocuments("not_Home", setNewDocumentsCountNotHome);
    
  }, [
    
    newDocumentsCountNotHome,
  ]);

  const handleOldDocuments=async(collectionName)=>{
    oldDocuments(collectionName)
  if(collectionName==="not_Home"){
    close(false);
    setNewDocumentCountNotHome("")

    alert("error")
  }


  }
useEffect(() => {
  const unsubscribe = configapp
    .firestore()
    .collection("not_Home")
    .orderBy("pressedTime", "desc")

    .onSnapshot(
      (querySnapshot) => {
        const data = [];
        querySnapshot
          .forEach((doc) => {
            // Push each document's data into the 'data' array
            data.push(doc.data());
          })
          .filter(
            (val) => val.noti === true && val.societyName === info.societyName
          );
        setNotHomeData(data);
      },
      (error) => {
        console.error("Error getting documents:", error);
      }
    );

  // Return the unsubscribe function in case you need to unsubscribe later
  return () => unsubscribe();
}, []);


  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
            // marginTop: "60px",
          },
        }}
        anchor="right"
        open={open}
        onClose={() =>{ 
          close(false);

        }
        }
      >
        <Box sx={{ padding: "2rem" }}>
          <ToastContainer />
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
            >
               Not at Home Persons Details
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() =>{
              handleOldDocuments("not_Home")
          // window.location.reload()
          }}>
              <CloseIcon />
            </Box>
          </Box>

          <Box>
          {securityData.length === 0 && (
            <Typography variant="body1">No more new requests</Typography>
          )}
            {securityData.length > 0 && securityData.map((securityDoc, index) => (
              <div key={index}>
                {/* Display the details of each document */}
                <Typography variant="h6">Person {index + 1}:</Typography>
               
                {/* <Typography variant="body1">
                <strong>NotHome:</strong> {securityDoc.Complain}
                </Typography> */}

                <Typography variant="body1">
                  <strong>Name:</strong> {securityDoc.Name}
                </Typography>

                <Typography variant="body1">
                  <strong>PhoneNo:</strong> {securityDoc.PhoneNo}
                </Typography>

                <Typography variant="body1">
                  <strong>Address:</strong> {securityDoc.Address}
                </Typography>

                <Typography variant="body1">
                  <strong>Days:</strong> {securityDoc.days}
                </Typography>

                {/* <Typography variant="body1">
                  <strong>Time:</strong> {securityDoc.time}
                </Typography> */}
                {/* <Typography variant="body1">
                  <strong>Date & Time:</strong> {securityDoc.pressedTime}
                </Typography> */}
                <hr />
              </div>
            ))}
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default NotHomeNoti;
