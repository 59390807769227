import React from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import clipboardIcon from '@iconify-icons/system-uicons/clipboard';
import pendingActionsIcon from '@iconify-icons/ic/outline-pending-actions';
import clipboardTickOutlineIcon from '@iconify-icons/teenyicons/clipboard-tick-outline';
import logoutOutlineIcon from '@iconify-icons/solar/logout-2-outline';
const userRoleData = localStorage.getItem("currentUser");
const currentUser = userRoleData ? JSON.parse(userRoleData).SubRole : null;

const mainListItemsClose = (
  <List className="mt-1 draw-side-lis">

  {currentUser === "button-three" && (
      <React.Fragment>
        <Link to="/Grocery">
         <ListItem className="draw-item-list">
         <Icon icon="ic:outline-local-grocery-store" color="#7b7b7b" fontSize= '45px' />   
         </ListItem>
       </Link>
       <Link to="/Orders">
     <ListItem className="draw-item-list">
     <Icon icon="solar:cart-5-linear" color="#7b7b7b"         fontSize= '25px' // Adjust the size as needed
 />      
     </ListItem>
   </Link>
       
      </React.Fragment>
    )}
    
    {currentUser === "button-one" && (
      <React.Fragment>
    <Link to="/Complaints">
  <ListItem className="draw-item-list">
    <Icon
      icon={clipboardIcon}
      style={{
        fontSize: '25px', // Adjust the size as needed
        color: '#7B7B7B', // Set the color
      }}
    />
  </ListItem>
</Link>

        <Link to="/Pending">
          <ListItem className="draw-item-list">
          <Icon
      icon={pendingActionsIcon}
      style={{
        fontSize: '25px', // Adjust the size as needed
        color: '#7B7B7B', // Set the color
      }}
    />            
          </ListItem>
        </Link>
        <Link to="/Completed">
          <ListItem className="draw-item-list">
          <Icon
  icon={clipboardTickOutlineIcon}
  style={{
    fontSize: '25px', // Adjust the size as needed
    color: '#7B7B7B', // Set the color
  }}
/>           
          </ListItem>
        </Link>
        {/* <Link to="/Complaints">
      <ListItem className="draw-item-list">
        <i class="fa fa-google-wallet" aria-hidden="true"></i>
        <h6>Complaints</h6>
      </ListItem>
    </Link> */}
    </React.Fragment>
    )}
   
   {currentUser === "button-two" && (
     
       <React.Fragment>
       <Link to="/Doctor">
        <ListItem className="draw-item-list">
        <Icon icon="healthicons:doctor-outline" color="#7b7b7b"  fontSize= '25px'// Adjust the size as needed
 />      
        </ListItem>
      </Link>
      <Link to="/Prescription">
    <ListItem className="draw-item-list">
    <Icon icon="fluent:doctor-48-regular" color="#7b7b7b" fontSize= '25px'/>  
    </ListItem>
  </Link>
     </React.Fragment>
    )}
    
    <Link>
      <ListItem
        className="draw-item-list"
        onClick={(e) => {
          localStorage.clear();
          window.location.href = "Login";
        }}
      >
<Icon
  icon={logoutOutlineIcon}
  style={{
    fontSize: '45px', // Adjust the size as needed
    color: '#7b7b7b', // Set the color to "#7b7b7b"
  }}
/>
        
      </ListItem>
    </Link>

  </List>
);

export default mainListItemsClose;