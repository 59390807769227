import React, { useState, useEffect } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../../firebase";
import { oldDocuments,getCountOfNewDocuments } from "../function";

const AddDoctorNoti = ({ open, close ,setNewDocumentCountNotHome}) => {
    
  const [securityData, setNotHomeData] = useState([]);
  const [newDocumentsCountDocNot, setNewDocumentsCountDocNot] = useState("");
  const info = JSON.parse(localStorage.getItem("currentUser"));


  useEffect(() => {
    // Get false document counts
    getCountOfNewDocuments("button-two", setNewDocumentsCountDocNot);
    
  }, [
    
    newDocumentsCountDocNot,
  ]);

  const handleOldDocuments=async(collectionName)=>{
   oldDocuments(collectionName)
  if(collectionName==="button-two"){
    close(false);
    setNewDocumentsCountDocNot("")

  }


  }
useEffect(() => {
  const unsubscribe = configapp
    .firestore()
    .collection("button-two")
    .orderBy("pressedTime", "desc")
    .onSnapshot(
      (querySnapshot) => {
        const data = [];
        querySnapshot
          .forEach((doc) => {
            data.push(doc.data());
          })
          .filter(
            (val) => val.noti === true && val.societyName === info.societyName
          );
        setNotHomeData(data);
      },
      (error) => {
        console.error("Error getting documents:", error);
      }
    );

  // Return the unsubscribe function in case you need to unsubscribe later
  return () => unsubscribe();
}, []);


  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
            // marginTop: "60px",
          },
        }}
        anchor="right"
        open={open}
        onClose={() =>{ 
          close(false);

        }
        }
      >
        <Box sx={{ padding: "2rem" }}>
          <ToastContainer />
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
            >
               New Patients List
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() =>{
              handleOldDocuments("button-two")
          }}>
              <CloseIcon />
            </Box>
          </Box>

          <Box>
          {securityData.length === 0 && (
            <Typography variant="body1">No more new requests</Typography>
          )}
            {securityData.length > 0 && securityData.map((securityDoc, index) => (
              <div key={index}>
                <Typography variant="h6">Patient {index + 1}:</Typography>
               

                <Typography variant="body1">
                  <strong>Name:</strong> {securityDoc.name}
                </Typography>

                <Typography variant="body1">
                  <strong>PhoneNo:</strong> {securityDoc.phoneNo}
                </Typography>

                <Typography variant="body1">
                  <strong>Address:</strong> {securityDoc.address}
                </Typography>

                <Typography variant="body1">
                  <strong>Email Address:</strong> {securityDoc.email}
                </Typography>

             
                <hr />
              </div>
            ))}
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default AddDoctorNoti;
