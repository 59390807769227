import { configureStore, combineReducers } from "@reduxjs/toolkit";
import Userslice from "./slices/Userslice";
import storage from "redux-persist/lib/storage";
import { persistReducer}  from "redux-persist";

const persistConfig={
  key:"root",
  version: 1,
  storage
}

const rootReducer = combineReducers({
  user: Userslice, 
});

const persistreducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistreducer,
});

export default store;
