import React, { useState } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../../firebase";
import { useSelector } from "react-redux";

const ContentPres = ({close}) => {
  const [medicine, setMedicine] = useState("");
  const [testNeeded, setTestNeeded] = useState("");
  const [thingsToDo, setThingsToDo] = useState("");
  const [disease, setDisease] = useState("");
  const prescData = useSelector((state) => state.user.prescriptionData);


  const addDataToFirebase = () => {

    console.log(userToken);
    if (medicine && thingsToDo && disease && testNeeded) {
      if (prescData.id) {
        const consultationData = {
          patient: {
            medicine: medicine,
            testNeeded: testNeeded,
            thingsToDo: thingsToDo,
            disease: disease,
            PatientID: prescData.patient.PatientID,
          },
          meetingStatus: "Meeting Held",
          prescription: true,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
        };


        configapp
          .firestore()
          .collection("consultation")
          // .limit(30)

          .doc(prescData.id) // Use the provided document ID
          .update(consultationData) // Update with the new data
          .then(() => {
            const notificationData = {
              title: "DOCTOR",
              description: `Prescription has been created. Tap to view`,
              image: "https://example.com/notification-image.jpg",
              date: new Date().toLocaleDateString(),
              time: new Date().toLocaleTimeString(),
              id: prescData.id,
              isRead: false,
              uid: prescData.patient.PatientID,
              pressedTime: new Date(),
            };

            // Add the notification data to the "notifications" collection
            configapp
              .firestore()
              .collection("notifications")
              .add(notificationData)
              .then(() => {})
              .catch((error) => {
                console.error("Error adding notification: ", error);
                notifyError();
              });
          })
          .catch((error) => {
            console.error("Error updating data: ", error);
            notifyError();
          });
      } else {
        console.error("Invalid data value or structure");
        notifyError();
      }
    } else {
      notifyError();
    }
  };
  
const userToken = prescData.Token;
const id = prescData.id;

  const sendPushNotification = async (userToken,id) => {
  
    try {
     
  
      const message = {
        notification: {
          title: "Prescription Generated",
          body: "Tap to Read it!",
        },
        to: userToken,
        data:{
documentId:id,
        },
      };
  
      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization":"key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
         
        },
        body: JSON.stringify(message),
      });
  
      if (response.ok) {
        // console.log("Push notification sent successfully");
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  const notify = () => toast.success("Successfully updated!");
  const notifyError = () => toast.error("Please fill the required fields");

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Doctor Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Box>

      <Box>
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Disease"
                value={disease}
                onChange={(e) => setDisease(e.target.value)}
                multiline
                id="outlined-multiline-flexible"
                maxRows={3}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Medicine"
                value={medicine}
                onChange={(e) => setMedicine(e.target.value)}
                multiline
                id="outlined-multiline-flexible"
                maxRows={3}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Test needed"
                value={testNeeded}
                onChange={(e) => setTestNeeded(e.target.value)}
                multiline
                id="outlined-multiline-flexible"
                maxRows={3}
              />
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Things to do"
                multiline
                id="outlined-multiline-flexible"
                maxRows={3}
                value={thingsToDo}
                onChange={(e) => setThingsToDo(e.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "12px", textAlign: "center" }}>
            <SolidButton
              label="Save"
              sx={{ marginTop: "2rem" }}
              onClick={() => {
                if (medicine && thingsToDo && disease&&testNeeded) {
                  addDataToFirebase();
                  close(false);
                  notify();
                  sendPushNotification(userToken,id);

                } else {
                  notifyError();
                }
              }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

function AddPrescriptionDrawer({
  open,
  close,

}) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3,
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentPres close={close} />
      </Drawer>
    </React.Fragment>
  );
}

export default AddPrescriptionDrawer;
