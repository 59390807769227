import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Drawer,
  Stack,
  Box,
  Typography,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid
} from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";


const ContentEdit = ({ close , reCall}) => {
  const compData = useSelector((state) => state.user.complainData)
  const documentId = compData.id;
  const userToken = compData.FCMtoken;
  const info = JSON.parse(localStorage.getItem("currentUser"));

  const sendPushNotification = async (userToken) => {
    try {
      const message = {
        notification: {
          title: "Complain Reviewed",
          body: "Tap to check!",
        },
        to: userToken,
      };
      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",

        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
      } else {
      }
    } catch (error) {
    }
  };

  console.log(compData.uid, " ooo")

  const Data = new Date().toString();

  const updateApi = async () => {
    console.log(documentId)
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/complain/${documentId}`, {
      complainStatus: "pending",
      status: false,
      count: false,
      pressedTime1: Data,
      edit: true
    })
    close(false)
    notiApi();
    reCall();
   
  }

  const notiApi = async ()=>{
    const notificationData = {
      description: "Complaint has been registered",
      image: "https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-test-icon-63758263.jpg",
      title: "COMPLAINT ",
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      id: compData.id,
      isRead: false,
      uid: compData.uid,
      pressedTime: new Date(),
      societyName: info.societyName

    };

    addDoc(collection(getFirestore(), "notifications"), notificationData)
      .then(() => {
        console.log("Notification added successfully.");
      })
      .catch((error) => {
        console.error("Error updating complainResponse:", error);
        notifyError();
      });
  }

 


  const notify = (message) => toast.success(message, {
    hideProgressBar: false,
  });
  const notifyError = () => toast.error("Error updating complainResponse", {
    hideProgressBar: false,
  });

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Complaints Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          variant="outlined"
          value={compData.complain}
          multiline
          placeholder="none"
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />{" "}
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <SolidButton
                label="Proceed"
                onClick={() => {
                  updateApi();
                  sendPushNotification(userToken);

                  console.log(documentId, "ooo ppp");
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};




function AddComplaintsDrawer({ open, close ,reCall}) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentEdit close={close} reCall={reCall} />

      </Drawer>
    </React.Fragment>
  );
}

export default AddComplaintsDrawer;
