import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Drawer,
  Stack,
  Box,
  Typography,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid
} from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const ContentEdit = ({ close, reCall }) => {
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const date = new Date();
  const [secCom, setSecCom] = useState("");
  const secData = useSelector((state) => state.user.securityData)
  const userToken = secData.FCMtoken;
  const documentId = secData.id;


  const updateApi = async () => {
    console.log(documentId)
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/security/${documentId}`, {
      securityStatus: false,
      count: false,
      securityComplain: secCom,
      FCMtoken: userToken,
      edit: true

    })
    close(false)
    notiApi()
    reCall()
    console.log(response)
  }

  const notiApi = async () => {
    const notificationData = {
      title: "SECURITY",
      description: "Security team is on its way. You may receive a call soon.",
      image: "https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-test-icon-63758263.jpg",
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      id: secData.id,
      isRead: false,
      uid: secData.uid,
      pressedTime: new Date(),
      societyName: info.societyName

    };

    addDoc(collection(getFirestore(), "notifications"), notificationData)
      .then(() => {
        console.log("Notification added successfully.");
      })
      .catch((error) => {
        console.error("Error updating complainResponse:", error);
        notifyError();
      });
  }

  const sendPushNotification = async (userToken) => {
    try {

      const message = {
        notification: {
          title: "Security team is on the way",
          body: "Tap to view Response!",
        },
        to: userToken,
      };

      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",

        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        // console.log("Push notification sent successfully");
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  // const handleProceed = () => {
  //   if (documentId) {
  //     configapp
  //       .firestore()
  //       .collection(info.SubRole)
  //       .doc(documentId)
  //       .update({
  //         securityStatus: false,
  //         count: false,
  //         securityComplain: secCom,
  //         Token:userToken,
  //         // pressedTime1: new Date(),

  //       })
  //       .then(() => {
  //         notify("Security Response updated to 'processing'");
  //         close(false);
  //       })
  //       const notificationData = {
  //         title: "SECURITY",
  //         description: `Security team is on its way. You may receive a call soon`,
  //         image:
  //           "https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-test-icon-63758263.jpg",
  //         date: new Date().toLocaleDateString(),
  //         time: new Date().toLocaleTimeString(),
  //         id: documentId,
  //         isRead: false,
  //         uid: secData.uid,
  //         pressedTime: new Date(),
  //       };

  //       // Add the notification data to the "notifications" collection
  //       addDoc(collection(getFirestore(), "notifications"), notificationData)
  //         .then(() => {
  //           console.log("Notification added successfully.");
  //           // You can notify the user about the successful notification addition here if needed
  //         })
  //       .catch((error) => {
  //         console.error("Error updating complainResponse:", error);
  //         notifyError();
  //       });
  //   } else {
  //     notifyError();
  //   }
  // };

  // const updateButtonOne = async (documentId) => {
  //   try {
  //     const buttonOneDocRef = doc(getFirestore(), "button-one", documentId);

  //     await updateDoc(buttonOneDocRef, {
  //       edit: true,
  //     });

  //     console.log("Document updated successfully!");
  //   } catch (error) {
  //     console.error("Error updating document:", error);
  //   }
  // };

  const notify = (message) => toast.success(message);
  const notifyError = () => toast.error("Error updating complainResponse");

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Security Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          variant="outlined"
          value={date}
          multiline
          placeholder="none"
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />
        <TextField
          fullWidth
          variant="outlined"
          value={secCom}
          multiline
          placeholder="Write security issue here!"
          onChange={(e) => setSecCom(e.target.value)}
          id="outlined-multiline-flexible"
          maxRows={5}

        />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <SolidButton
                label="Proceed"
                onClick={() => {
                  // handleProceed();
                  updateApi()
                  sendPushNotification(userToken);
                  // updateButtonOne(documentId);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function AddSecurityDrawer({ open, close, reCall }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3

          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentEdit close={close} reCall={reCall}/>
      </Drawer>
    </React.Fragment>
  );
}

export default AddSecurityDrawer;
