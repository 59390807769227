import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Orders from "./components/adminPanel/Orders/Orders"
import CompletedGrocery from "./components/adminPanel/CompletedGrocery/CompletedGrocery";
import Private from "./components/PirvateRoute";
import Grocery from "./components/adminPanel/Grocery/Grocery";
import Doctor from "./components/adminPanel/Doctor/Doctor";
import Prescription from "./components/adminPanel/Prescription/Prescription";
import CompletedDoctor from "./components/adminPanel/CompletedDoctor/CompletedDoctor";
import Complaints from "./components/adminPanel/Complaints/Complaints";
import Pending from "./components/adminPanel/Pending/Pending";
import Completed from "./components/adminPanel/Completed/Completed";
import Security from "./components/adminPanel/Security/Security";
import ProcessSec from "./components/adminPanel/ProcessSec/ProcessSec";
import CompletedSecurity from "./components/adminPanel/CompletedSecurity/CompletedSecurity";
import Offline from "./components/Offline";
import NotHome from "./components/adminPanel/NotHome/NotHome";
import VideoPanel from "./components/adminPanel/VideoPanel/VideoPanel";
import Residence from "./components/adminPanel/Residence/Residence";
import CMS from "./components/adminPanel/CMS/CMS";
import Discounts from "./components/adminPanel/Discounts/Discounts";
import NotHomeCancelled from "./components/adminPanel/NotHomeCancelled/NotHomeCancelled";
import NotHomeCompleted from "./components/adminPanel/NotHomeCompleted/NotHomeCompleted";
import Billing from "./components/adminPanel/Billing/Billing";
import Plots from "./components/adminPanel/Plots/Plots";
import NotificationPanel from "./components/adminPanel/NotificationPanel/NotificationPanel";
import Status from "./components/adminPanel/Status/Status";
import Logo from "./components/adminPanel/Logo/Logo";
import { socket } from "./socket"
import Annoucements from "./components/adminPanel/Annoucements/Annoucements";
import { Login } from "./components/adminPanel/Login";

function App() {
  const userRoleData = localStorage.getItem("currentUser");
  const currentUser = userRoleData ? JSON.parse(userRoleData).SubRole : null;
  const userId = userRoleData ? JSON.parse(userRoleData)?._id : null
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window?.addEventListener("online", handleStatusChange);

    window?.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  useEffect(() => {
    socket.emit('new-user-add', userId)
  }, [])

  return (
    <>
      {isOnline ? (
        <Switch>
          <Route exact path="/Login" component={Login} />
          <Private
            exact
            path="/"
            component={
              currentUser === "button-three"
                ? Grocery
                : currentUser === "button-two"
                  ? Doctor
                  : currentUser === "button-one"
                    ? Security
                    : currentUser === "complian"
                      ? Complaints
                      : currentUser === "discounts"
                        ? Discounts
                        : currentUser === "residence"
                          ? Residence
                          : currentUser === "billing"
                            ? Billing
                            : currentUser === "plots"
                              ? Plots
                              : currentUser === "notification"
                                ? NotificationPanel
                                : currentUser === "status"
                                  ? Status
                                  : currentUser === "logo"
                                    ? Logo
                                    : currentUser === "cms"
                                      ? CMS
                                      : currentUser === "super-admin"
                                        ? NotificationPanel
                                        : currentUser === "cms-all"
                                          ? CMS
                                          : currentUser === "annoucements"
                                            ? Annoucements
                                            : VideoPanel
            }
          />
          <Private exact path="/Grocery" component={Grocery} />
          <Private exact path="/Orders" component={Orders} />
          <Private
            exact
            path="/CompletedGrocery"
            component={CompletedGrocery}
          />
          <Private exact path="/Doctor" component={Doctor} />
          <Private exact path="/Prescription" component={Prescription} />
          <Private exact path="/CompletedDoctor" component={CompletedDoctor} />
          <Private exact path="/Complaints" component={Complaints} />
          <Private exact path="/Pending" component={Pending} />
          <Private exact path="/Completed" component={Completed} />
          <Private exact path="/Security" component={Security} />
          <Private exact path="/ProcessSec" component={ProcessSec} />
          <Private
            exact
            path="/CompletedSecurity"
            component={CompletedSecurity}
          />
          <Private exact path="/NotHome" component={NotHome} />
          <Private
            exact
            path="/NotHomeCancelled"
            component={NotHomeCancelled}
          />
          <Private
            exact
            path="/NotHomeCompleted"
            component={NotHomeCompleted}
          />
          <Private exact path="/VideoPanel" component={VideoPanel} />
          <Private exact path="/Residence" component={Residence} />
          <Private exact path="/CMS" component={CMS} />

          <Private exact path="/Discounts" component={Discounts} />

          <Private exact path="/Billing" component={Billing} />
          <Private exact path="/Plots" component={Plots} />

          <Private
            exact
            path="/NotificationPanel"
            component={NotificationPanel}
          />
          <Private exact path="/Status" component={Status} />
          <Private exact path="/Logo" component={Logo} />
          <Private exact path="/Annoucements" component={Annoucements} />
        </Switch>
      ) : (
        <Offline />
      )}
    </>
  );
}

export default App;
