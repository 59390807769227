import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { configapp } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddViewDrawerSecurity from "./AddViewDrawerSecurity";
import { setCompletedSecurityData } from "../../store/slices/Userslice";
import { useDispatch } from "react-redux";
import axios from "axios";

const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: "purple",
    fontWeight: "1800",
    borderRight: "none",
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: "none",
  },
}));

const CompletedSecurity = (props) => {
  const classes = useStyles();
  const [openView, setOpenView] = useState(false);
  const [rowData, setRowData] = useState([]);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDataApi()

  }, []);

  const fetchDataApi = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/security/`)
    console.log(response.data)
    const data = response.data.map(({ ...rest }) => ({ ...rest, uid: rest._id, id: rest._id, userId: rest?._id }))
    console.log('data', data)
    setRowData(data)
    console.log(rowData, "ooo")
  }

  const handleResponseView = (row) => {
    setOpenView(true);
    dispatch(setCompletedSecurityData(row))
  };

  const viewColumn = {
    field: "View",
    width: 120,
    renderCell: (cellData) => (
      <RemoveRedEyeIcon
        style={{ margin: "0 10px", cursor: "pointer" }}
        onClick={(e) =>
          handleResponseView(cellData.row)
        }
      >
        Action
      </RemoveRedEyeIcon>
    ),
  };


  return (
    <>
      <AdminDashboard>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            className={classes.roots}
            style={{
              width: "100%",
              borderRadius: "15px",
              padding: "10px",
              background: "white",
              boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
              marginTop: "50px",
              marginLeft: "-15px",
            }}
            columns={[
              viewColumn,

              { field: "name", headerName: "Name", width: 180 },
              { field: "phoneNo", headerName: "Phone Number", width: 180 },
              { field: "address", headerName: "Address", width: 200 },
              { field: "email", headerName: "Email Address", width: 200 },
              { field: "fname", headerName: "Father Name", width: 180 },
              { field: "fPhoneNo", headerName: "Father Phone#", width: 180 },
              { field: "fmName", headerName: "Family Member Name", width: 200 },
              {
                field: "fmphoneNo",
                headerName: "Family Member Phone#",
                width: 200,
              },
              {
                field: "fmName1",
                width: 180,
                headerName: "Family Member Name",
              },
              {
                field: "fmphoneNo1",
                width: 180,
                headerName: "Family Membe Phone#",
              },
            ]}
            rows={rowData.filter((val) => val.securityStatus === true &&
              val.societyName === info.societyName)}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    backgroundColor: "white",
                    border: "black",
                    boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "600px",
                    marginLeft: "150px",
                    height: "35px",
                    marginTop: "-60px",
                  }}
                >
                  <GridToolbar {...props} />
                </div>
              ),
            }}
            getRowClassName={rowClassName(classes)}
          />
        </div>

        <AddViewDrawerSecurity
          open={openView}
          close={() => setOpenView(false)}
        />
      </AdminDashboard>
    </>
  );
};

export default CompletedSecurity;
