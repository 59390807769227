import React, { useState } from "react";
import { TextField, Button} from "@mui/material";
import { configapp } from "../../../firebase";
import AdminDashboard from "../Dashboard";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {  toast } from 'react-toastify';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const Status = (props) => {
  const [textValue, setTextValue] = useState("");
  

  const [residentData, setResidentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = React.useState('');
const [documentId,setDocumentId]=React.useState('');
  

  const db = configapp.firestore();

  const notifyError = () => {
    toast.error("No matching document found.")
  };

  const notify = (message) => {
    toast.success(message);
  };


 const handleFind = async () => {
  setIsLoading(true);
  try {
    const name = textValue.trim();
    const collection = db.collection("UserRequest");
    const unsubscribe = collection
      .where("Name", "==", name)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.size === 0) {
          setResidentData(null);
          notifyError();
        } else {
          const documentData = querySnapshot.docs[0].data();
          const selectedFields = {
            Name: documentData.Name,
            House_no: documentData.address,
            TFM: documentData.TFM,
            Phone_Number: documentData.phonenumber,
            Father_Name: documentData.fName,
            Father_Phone_number: documentData.fPhonenumber,
            Status: documentData.status,
          };
          setResidentData(selectedFields);
          setDocumentId(querySnapshot.docs[0].id);
        }
        setIsLoading(false);
      }, (error) => {
        console.error("Error searching for resident data:", error);
        setIsLoading(false);
      });

    // Clean up the listener when component unmounts or when the function is recalled
    return () => unsubscribe();
  } catch (error) {
    console.error("Error searching for resident data:", error);
    setIsLoading(false);
  }
};


  const handleSave = async () => {
    try {
  
      const userRef = db.collection("UserRequest").doc(documentId);
      const doc = await userRef.get();
  
      if (!doc.exists) {
        notifyError();
        console.error("Document not found");
        return;
      }
  
      await userRef.update({
        status: status,
      });
  
      notify("Status updated successfully");
    } catch (error) {
      console.error("Error updating status:", error);
      notifyError();
    }
  };
  


  
  return (
    <AdminDashboard>

<div
style={{marginTop:"20px", textAlign:"center"}}
>
      <TextField
      style={{width:"100%"}}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        placeholder="Enter Full Name"
      />

      <div style={{ display: "flex",justifyContent:"center", marginTop: "20px" }}>
        <Button
          onClick={() => handleFind()}
          style={{
            width: "90px",
            color: "white",
            backgroundColor: "#03136C",
            borderRadius: "10px",
            textAlign: "center",
            marginRight: "10px",
          }}
          disabled={isLoading}
        >
          Verify
        </Button>

     

      </div>
     
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : residentData ? (
        <div style={{ marginTop: "10px" }}>
          <div>
            <h2 style={{ textAlign: "center" }}>Resident Data</h2>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>User</strong></TableCell>
                    <TableCell><strong>Value</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(residentData).map(([property, value]) => (
                    <TableRow key={property}>
                      <TableCell>{property}</TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FormControl 
            style={{ width: "25%", marginTop: "20px" }}
            >
  <Select
   onChange={(e) => setStatus(e.target.value)} value={status}>
    <MenuItem value="Approve">Approve</MenuItem>
    <MenuItem value="Block">Block</MenuItem>
  </Select>

  <Button
  onClick={
    ()=>{
        alert(`Are you sure to change status to ${status}`);
        console.log("working")
        handleSave();
  }
}
style={{marginTop:"20px"}}
  >Save</Button>
</FormControl>

          </div>
        
         <div
         style={{marginTop:"20px", textAlign:"center"}}
         >
          
          </div>
        </div>
      ) : null}
    </AdminDashboard>
  );
};

export default Status;
