import React, { useState } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField, Checkbox } from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const ContentSecurity = ({ close, reCall }) => {
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const [complaintResponse, setComplaintResponse] = useState("pending");
  const [response, setResponse] = useState("");
  const secData = useSelector((state) => state.user.processSecData);
  const id = secData.id;
  const userToken = secData.FCMtoken;

  const updateApi = async () => {
    console.log(id)
    const data = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/security/${id}`, {
      response: response,
      securityStatus: true,
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
    })
    close(false)
    notiApi()
    reCall()
    console.log(data)
  }
 

  const notiApi = async () => {
    const notificationData = {
      title: "SECURITY",
      description: "Security issue resolved.",
      image: "https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-test-icon-63758263.jpg",
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      id: secData.id,
      isRead: false,
      uid: secData.uid,
      pressedTime: new Date(),
      societyName: info.societyName

    };

    addDoc(collection(getFirestore(), "notifications"), notificationData)
      .then(() => {
        console.log("Notification added successfully.");
      })
      .catch((error) => {
        console.error("Error updating complainResponse:", error);
        notifyError();
      });
  }

  const sendPushNotification = async (userToken) => {
    try {
      const message = {
        notification: {
          title: "Security Completed",
          body: "Action has been taken against your security request",
        },
        to: userToken,
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  const handleCheckboxChange = () => {
    setComplaintResponse(complaintResponse === "pending" ? "completed" : "pending");
  };


  const notify = () => toast.success("Response submitted successfully!");
  const notifyError = () => toast.error("Please check Response and Checkbox is enable! ");

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Security Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Box>

      <Box>
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                value={secData.securityComplain}
                multiline
                id="outlined-multiline-flexible"
                maxRows={5}
                disabled
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Response"
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                multiline
                id="outlined-multiline-flexible"
                maxRows={5}
                required
              />
              <Checkbox
                inputProps={{ "aria-label": "Checkbox demo" }}
                onChange={handleCheckboxChange}
                checked={complaintResponse === "completed"}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "12px", textAlign: "center" }}>
            <SolidButton
              label="Submit"
              sx={{ marginTop: "2rem" }}
              onClick={() => {
                if (response && complaintResponse === "completed") {
                  updateApi();
                  sendPushNotification(userToken);
                  close(false);
                } else {
                  notifyError();
                }
              }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};


function AddProcessDrawer({ open, close, reCall }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentSecurity close={close} reCall={reCall} />
      </Drawer>
    </React.Fragment>
  );
}

export default AddProcessDrawer;
