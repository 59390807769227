import React, { useState,useRef } from "react";
import { TextField, Button } from "@mui/material";
import { configapp } from "../../../firebase"; 
import AdminDashboard from "../Dashboard";
import { Stack } from "react-bootstrap";
import WebAssetIcon from "@mui/icons-material/WebAsset"; 
import * as XLSX from 'xlsx';
import {  toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';




const Billing = (props) => {
  const [textValue, setTextValue] = useState("");
  const [residentData, setResidentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showInputFields, setShowInputFields] = useState(false); 
  const [field1Value, setField1Value] = useState("45"); 
  const [field2Value, setField2Value] = useState("");
  const [field3Value, setField3Value] = useState("");
  
const[userToken,setUserToken]=useState("");
const[docId,setDocId]=useState("");
const[uid,setUid]=useState("");

const [ setSelectedFile] = useState(null); 
  const mediaInputRef = useRef(null); 
  const [mediaPath, setMediaPath] = useState(""); 
  const [excelData, setExcelData] = useState([]);
  const [uidDataWithUID, setUidDataWithUID] = useState([]);
  const [uidDataWithoutUID, setUidDataWithoutUID] = useState([]);
 



const notifyError=()=>{
  toast.error("No matching document found.")
} 
const notify=()=>{
  toast.success("Successfully Submit.")
} 

  const db = configapp.firestore();

  const handleSave = () => {
    const dataToSave = {
      uid: uid, 
      name: residentData.name,
      phonenumber: residentData.phonenumber,
      address: residentData.address,
      meterUnits: parseFloat(field2Value),
      meterRatePerUnit: parseFloat(field1Value),
      Total: parseFloat(field3Value),
      };

    db.collection("billing") 
      .add(dataToSave)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setDocId(docRef.id)
        
      })

      .catch((error) => {
        console.error("Error adding document: ", error);
      });
      const notificationData = {
        title: "BILL",
        description: `Bill has have been uploaded`,
        image: "https://example.com/notification-image.jpg",
        date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          isRead:false,
          id:docId,
      };
    
      configapp
        .firestore()
        .collection("notifications")
        .add(notificationData)

        .then(() => {
          console.log("Notification added successfully.");
        })
        .catch((error) => {
          console.error("Error adding notification: ", error);
        });
  };

  const sendPushNotification = async (userToken) => {
    try {
      const message = {
        notification: {
          title: "Bill Generated",
          body: "Tap to check!",
        },
        to: userToken,
      };
  
      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });
  
      if (response.ok) {
        console.log("Push notification sent successfully");
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };
  
  const handleFind = async () => {
    setIsLoading(true);
    try {
      const name = textValue.trim();

      const collection = db.collection("UserRequest");

      // Listen for real-time updates to the query
      collection.where("Name", "==", name).onSnapshot((snapshot) => {
        if (snapshot.size === 0) {
          setResidentData(null);
          notifyError();
        } else {
          const documentData = snapshot.docs[0].data();
          const selectedFields = {
            Name: documentData.Name,
            House_no: documentData.address,
            Block: documentData.Block,
            Phone_Number: documentData.phonenumber,
            Father_Name: documentData.fName,
            Father_Phone_number: documentData.fPhonenumber,
          };
          setResidentData(selectedFields);
          setUserToken(documentData.FCM_Token);
          setUid(documentData.uid);
          console.log(userToken);
        }
      });
    } catch (error) {
      console.error("Error searching for resident data:", error);
      // alert("An error occurred while searching for resident data.");
    } finally {
      setIsLoading(false);
    }
  };


  const handleShowInputFields = () => {
    setShowInputFields(true);

  };
  const calculateTotal = () => {
    const meterRatePerUnit = parseFloat(field1Value);
    const meterUnits = parseFloat(field2Value);
    const Total = meterRatePerUnit * meterUnits;
    setField3Value(Total.toFixed(2)); 
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setMediaPath(file.name);
  
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
  
        const sheet = workbook.Sheets['Sheet1'];
  
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        
  
        setExcelData(jsonData);
  
        console.log("Fetched Excel data:", jsonData);
      };
  
      reader.readAsArrayBuffer(file);

     
    } else {

      // toast("Please select a valid Excel file.");
    }
  };
 
  
  
  const fetchUIDsByAddress = (excelData) => {
    const excelAddresses = excelData.map((entry) => entry.address);
    const mergedData = excelData.map((entry) => ({
      ...entry,
      uid: "",
    }));

    const unsubscribe = db.collection("UserRequest").onSnapshot(
      (querySnapshot) => {
        const documentsToCreate = [];

        querySnapshot.forEach((doc) => {
          const firestoreData = doc.data();
          const firestoreAddress = firestoreData.address;
          const uid = firestoreData.uid;

          const matchedEntry = mergedData.find(
            (entry) => entry.address === firestoreAddress
          );

          if (matchedEntry && uid) {
            matchedEntry.uid = uid;
            documentsToCreate.push({ ...matchedEntry });
          }
        });

        if (documentsToCreate.length > 0) {
          const batch = db.batch();
          const billingRef = db.collection("billing");

          documentsToCreate.forEach((docData) => {
            const newDocRef = billingRef.doc();
            batch.set(newDocRef, docData);
          });

          batch
            .commit()
            .then(() => {
              console.log("Documents successfully created!");
            })
            .catch((error) => {
              console.error("Error creating documents: ", error);
            });
        }

        console.log("Merged Data:", mergedData);
      },
      (error) => {
        console.error("Error fetching Firestore data:", error);
      }
    );

    // Return the unsubscribe function in case you need to unsubscribe later
    return unsubscribe;
  };


  

  // const fetchUIDsByAddressWithoutUpload = async (excelData) => {
  //   const excelAddresses = excelData.map((entry) => entry.address);
  //   const mergedData = excelData.map((entry) => ({
  //     ...entry,
  //     uid: '',
  //   }));
  
  //   try {
  //     const querySnapshot = await db.collection("UserRequest").get();
  //     const dataToFetch = [];
  
  //     querySnapshot.forEach((doc) => {
  //       const firestoreData = doc.data();
  //       const firestoreAddress = firestoreData.address;
  //       const uid = firestoreData.uid;
  
  //       const matchedEntry = mergedData.find((entry) => entry.address === firestoreAddress);
  
  //       if (matchedEntry) {
  //         matchedEntry.uid = uid;
  //         dataToFetch.push({ ...matchedEntry });

  //       }
  //     });
  
  //     console.log(" Data:", dataToFetch);
  //   } catch (error) {
  //     console.error("Error fetching Firestore data:", error);
  //   }
  // };
 const fetchUIDsByAddressWithoutUpload = async (excelData) => {
   const excelAddresses = excelData.map((entry) => entry.address);
   const mergedData = excelData.map((entry) => ({
     ...entry,
     uid: "",
   }));

   try {
     const unsubscribe = db.collection("UserRequest").onSnapshot(
       (querySnapshot) => {
         const dataToFetch = mergedData.slice();

         querySnapshot.forEach((doc) => {
           const firestoreData = doc.data();
           const firestoreAddress = firestoreData.address;
           const uid = firestoreData.uid;

           const matchedEntry = dataToFetch.find(
             (entry) => entry.address === firestoreAddress
           );

           if (matchedEntry) {
             matchedEntry.uid = uid;
           }
         });

         console.log("Merged Data:", dataToFetch);
         console.log(
           "Data with UID:",
           dataToFetch.filter((entry) => entry.uid)
         );
         console.log(
           "Data without UID:",
           dataToFetch.filter((entry) => !entry.uid)
         );

         setUidDataWithUID(dataToFetch.filter((entry) => entry.uid));
         setUidDataWithoutUID(dataToFetch.filter((entry) => !entry.uid));
       },
       (error) => {
         console.error("Error fetching Firestore data:", error);
       }
     );

     // Return the unsubscribe function in case you need to unsubscribe later
     return unsubscribe;
   } catch (error) {
     console.error("Error fetching Firestore data:", error);
   }
 };

  
  
  
 
  return (
    <AdminDashboard>
      <TextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        placeholder="Enter Full Name"
      />


      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button
          onClick={()=>{
            handleFind();
          }}
          style={{
            width: "90px",
            color: "white",
            backgroundColor: "black",
            borderRadius: "10px",
            textAlign: "center",
          }}
          disabled={isLoading}
        >
          Verify
        </Button>

      </div>

      <div style={{ marginTop: "20px" }}> 
        <TextField
                    variant="outlined"
                    placeholder="Upload Media"
                    style={{ width: '180px', marginBottom: '10px' }}
                    onClick={() => mediaInputRef.current.click()}
                    value={mediaPath}
                    disabled
                  /> 
                  
                      <WebAssetIcon
                        label="Browse Media"
                        fontSize="large"
                        cursor="pointer"
                       
                        onClick={() => {
                          mediaInputRef.current.click()
                                                }}
                        style={{marginTop:"10px"}}
                      />
                      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileInputChange}
        ref={mediaInputRef}
        style={{ display: "none" }}
      />

<Button
          style={{marginTop:"10px", 
          marginLeft:"5px",
          width: "80px",
          color: "white",
          backgroundColor: "black",
          borderRadius: "10px",
          textAlign: "center",
        }}
          onClick={()=>{
            // fetchUIDsByAddress(excelData);
            fetchUIDsByAddressWithoutUpload(excelData);
            // console.log(dataToFetch)

            }}>
            
            
            Fetch
          </Button>

     {uidDataWithUID.length > 0 && ( 
      <div>
  <h2 style={{textAlign:"center"}}>File Data</h2>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone#</TableCell>
          <TableCell>House#</TableCell>
          <TableCell>Block</TableCell>
          <TableCell>Unit Rate</TableCell>
          <TableCell>Unit Consumed</TableCell>
          <TableCell>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {uidDataWithUID.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.index}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.phonenumber}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.block}</TableCell>
            <TableCell>{row.meterRatePerUnit}</TableCell>
            <TableCell>{row.meterUnits}</TableCell>
            <TableCell>{row.Total}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  <div
  style={{
    textAlign: "center",
  }}
  >
  <Button
          style={{marginTop:"10px", 
          // marginLeft:"5px",
          width: "80px",
          color: "white",
          backgroundColor: "black",
          borderRadius: "10px",
         
        }}
          onClick={()=>{
            fetchUIDsByAddress(excelData);
            notify();
            
            // fetchUIDsByAddressWithoutUpload(excelData);
            // console.log(dataToFetch)
  }}>
                Save
          </Button>
          </div>
  </div>
     )}

{uidDataWithoutUID.length > 0 && ( 
<div
style={{marginTop:"15px"}}
>
<h2 style={{textAlign:"center"}}>Mis-Matched Data</h2>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone#</TableCell>
          <TableCell>House#</TableCell>
          <TableCell>Block</TableCell>
          <TableCell>Unit Rate</TableCell>
          <TableCell>Unit Consumed</TableCell>
          <TableCell>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {uidDataWithoutUID.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.index}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.phonenumber}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.block}</TableCell>
            <TableCell>{row.meterRatePerUnit}</TableCell>
            <TableCell>{row.meterUnits}</TableCell>
            <TableCell>{row.Total}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</div>
)}

    </div>
 {isLoading ? (
  <p>Loading...</p>
) : residentData ? (
  <div style={{marginTop:"10px"}}>
    <div 
    // style={{display:"flex"}}
     >
    <h2 style={{textAlign:"center"}}>Resident Data</h2>

{/* <button 
style={{float:"right",borderRadius:"30px",
width:"30px",height:"30px",
 border:"none",background:"grey"}}
onClick={()=>{
  residentData(false);
  }}
>X</button> */}
    </div>
   <div>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>User</strong></TableCell>
            <TableCell><strong>Value</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(residentData).map(([property, value]) => (
            <TableRow key={property}>
              <TableCell>{property}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
          <div 
          style={{marginTop:"20px"}}
          >      
                <Button onClick={handleShowInputFields}>
          Generate Bill
          </Button>
          
          </div>

         

          {showInputFields && (
            <div>
              <Stack>
                <TextField
                  style={{ width: "25%", marginBottom: "10px" }}
                  value={45}
                  onChange={(e) => setField1Value(e.target.value)}
                  placeholder="Rate per Unit"
                  type="number"
                  onBlur={calculateTotal} 
                />
                <TextField
                  style={{ width: "25%", marginBottom: "10px" }}
                  value={field2Value}
                  onChange={(e) => setField2Value(e.target.value)}
                  placeholder="Unit Consumed"
                  type="number"
                  onBlur={calculateTotal} 
                />
                <TextField
                  style={{ width: "25%", marginBottom: "10px" }}
                  value={field3Value}
                  placeholder="Total"
                  disabled
                />
                <Button
                  style={{ width: "25%", marginBottom: "10px",backgroundColor:"black",color:"white" }}
                onClick={()=>{
                    handleSave();
                    sendPushNotification(userToken);
                }}
                >
                  Save
                </Button>
              </Stack>
            </div>
          )}
         
        </div>
      ) : null}
    </AdminDashboard>
  );
};

export default Billing;
