import React from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const ContentComplain = ({ close}) => {

const secData = useSelector((state) => state.user.completedSecurityData);

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Security Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Box>

      <Box>
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Complaint"
                value={secData.securityComplain}
                multiline
                id="outlined-multiline-flexible"
                maxRows={5}
                disabled
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Response"
                value={secData.response}
                multiline
                id="outlined-multiline-flexible"
                maxRows={5}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};


function AddViewDrawerSecurity({ open, close}) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentComplain close={close}  />
      </Drawer>
    </React.Fragment>
  );
}

export default AddViewDrawerSecurity;
