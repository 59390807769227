import React from "react";
import { Button } from "@mui/material";

export function SolidButton({ onClick, label, btnWidth, ...props }) {
  return (
    <Button
      size="small"
      variant="contained"
      {...props}
      sx={{
        background:
          "linear-gradient( -20deg, #2b5876 0%, #4e4376 100% ) !important",
        padding: "1rem 2rem",
        borderRadius: "0.5rem",
        fontSize: "14px",
        width: btnWidth,
        height: "35px",
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}
