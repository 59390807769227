import React, { useState, useEffect } from "react";
import { Drawer, Box, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "firebase/compat/firestore";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import "firebase/compat/messaging";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ContentView = ({ close, open, }) => {
 
 const grocData = useSelector((state) => state.user.completedGroceryData)

  if (!grocData) {
    return <CircularProgress/>; 
  }

  return (
    <Drawer
      sx={{
        display: { sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          overflowX: "hidden",
          width: "40%",
          position: "fixed",
          zIndex: "990",
          borderRadius: "10px",
          boxShadow: 3,
        },
      }}
      anchor="right"
      open={open}
      onClose={close}
    >
      <Box sx={{ padding: "20px" }}>
        <CardContent>
          <Typography
            variant="h5"
            sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Rooboto" }}
          >
            Grocery Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-35px",
            }}
          >
            <CloseIcon sx={{ cursor: "pointer" }} onClick={close} />
          </Box>
        </CardContent>

        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Items</StyledTableCell>
                <StyledTableCell align="right">Quantity</StyledTableCell>
                <StyledTableCell align="right">Currency</StyledTableCell>
                <StyledTableCell align="right">Price</StyledTableCell>
                <StyledTableCell align="right">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grocData.items?.length > 0 &&
                grocData.items.map((item) => (
                  <StyledTableRow key={item?.itemName}>
                    <StyledTableCell component="th" scope="row">
                      {item?.itemName}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item?.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item?.currency}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item?.price}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item?.total}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Drawer>
  );
};

function AddViewDrawer({ open, close }) {
  const [isLoading, setIsLoading] = useState(true);
const grocData = useSelector((state)=> state.user.completedGroceryData)

 useEffect(() => {
   if (grocData) {
     setIsLoading(false);
   }
 }, [grocData]);


  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ContentView close={() => close(false)}  open={open}/>
        )}
      </Drawer>
    </React.Fragment>
  );
}

export default AddViewDrawer;
