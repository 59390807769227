import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
 
  doc,
  updateDoc,
} from "firebase/firestore";

import { Drawer, Stack, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { configapp } from "../../../firebase";
import { useSelector } from "react-redux";

const ContentEdit = ({ close, data }) => {
  const [meetId, setMeetId] = useState("");
  const [description, setDescription] = useState("");
  const [medicine] = useState("");
  const [testNeeded] = useState("");
  const [thingsToDo] = useState("");
  const [disease] = useState("");
  const [DiD, setDID] = useState("");
  const [docuId, setDocuId] = useState("");
  const info = JSON.parse(localStorage.getItem("currentUser"));

  const doctorData = useSelector((state) => state.user.doctorData);


  const addDataToFirebase = async () => {
    if (meetId) {
      if (doctorData.FCMtoken) {
        const currentDate = new Date();
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            const userId = user.uid;

            const consultationData = {
              doctor: {
                patientName: doctorData.name,
                patientPhone: doctorData.phoneNo,
                patientAddress: doctorData.address,
                patientEmail: doctorData.email,
                meetingId: meetId,
                description: description,
                DoctorID: userId,
              },
              patient: {
                medicine: medicine,
                testNeeded: testNeeded,
                thingsToDo: thingsToDo,
                disease: disease,
                PatientID: doctorData.uid,
              },
              count: false,
              uid: doctorData.uid,
              meetingStatus: "Link Generated",
              date: currentDate.toLocaleDateString(),
              time: currentDate.toLocaleTimeString(),
              Token: doctorData.FCMtoken,
              pressedTime: new Date(),
              prescription: false,
              FMname: doctorData.fmName,
              FMphone: doctorData.fmphoneNo,
              FMname1: doctorData.fmName1,
              FMphone1: doctorData.fmphoneNo1,
              Fname: doctorData.fname,
              Fphone: doctorData.fPhoneNo,
              societyName: info.societyName,
            };

            const consultationRef = configapp
              .firestore()
              // .limit(30)

              .collection("consultation")
              .add(consultationData)
              .then((docRef) => {
                setDocuId(docRef.id);

                const notificationData = {
                  title: "DOCTOR",
                  description: `Meet ID has been generated`,
                  image:
                    "https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-test-icon-63758263.jpg",
                  date: new Date().toLocaleDateString(),
                  time: new Date().toLocaleTimeString(),
                  isRead: false,
                  id: docRef.id,
                  uid: doctorData.uid,
                  pressedTime: new Date(),
                };

                const notificationRef = configapp
                  .firestore()
                  .collection("notifications")
                  .add(notificationData)
                  // .limit(30)

                  .then(() => {
                    notify();
                    setDID(docRef.id);

                    // Send a push notification
                    sendPushNotification(doctorData.FCMtoken);
                  })
                  .catch((error) => {
                    console.error("Error adding notification: ", error);
                    notifyError();
                  });
              })
              .catch((error) => {
                console.error("Error saving data: ", error);
                notifyError();
              });
          }
        });

        return () => {
          unsubscribe();
        };
      } else {
        notifyError();
      }
    } else {
      notifyError();
    }
  };

  const docId = doctorData.id;

  const updateButtonTwo = async (docId) => {
    try {
      const buttonTwoDocRef = doc(getFirestore(), "button-two", docId);
      await updateDoc(buttonTwoDocRef, {
        edit: true,
      });
      console.log("Document updated successfully!");
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const sendPushNotification = async (userToken) => {
    try {
      const message = {
        notification: {
          title: "Link Generated",
          body: "Meet link with doctor has been generated",
        },
        to: userToken,
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        // console.log("Push notification sent successfully");
      } else {
        // console.error("Error sending push notification.");
      }
    } catch (error) {
      // console.error("Error sending push notification:", error);
    }
  };

  const notify = () => toast.success("Successfully updated!");
  const notifyError = () => toast.error("Please fill the required fields");
  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Doctor Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              required
              id="required"
              label="Meet ID"
              placeholder="Paste Meet ID here.."
              value={meetId}
              onChange={(e) => setMeetId(e.target.value)}
              sx={{ padding: "3px" }}
            />
            <TextField
              id="outlined-textarea"
              label="Description"
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <SolidButton
                label="Generate"
                onClick={() => {
                  if (meetId) {
                    addDataToFirebase();
                    updateButtonTwo(docId);
                    close(false);
                  } else {
                    notifyError();
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function AddDoctorDrawerEdit({ open, close }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3,
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentEdit close={close} />
      </Drawer>
    </React.Fragment>
  );
}
export default AddDoctorDrawerEdit;
