import React from 'react'

const Offline = () => {
  return (
    <div>
      <h1>You are currently offline</h1>
    </div>
  )
}

export default Offline
