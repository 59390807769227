import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AddResponseDrawer from "./AddResponseDrawer";
import { useDispatch } from "react-redux";
import { setPendingData } from "../../store/slices/Userslice";
import axios from "axios";


const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: 'purple',
    fontWeight: '1800',
    borderRight: 'none',
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: 'none',
  },
}));


const Pending = (props) => {
  const classes = useStyles(); const [openPres, setOpenPres] = React.useState(false);
  const [rowData, setRowData] = useState([]);

  const info = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDataApi()
  }, []);

  const fetchDataApi = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/complain/`)
    console.log(response.data)
    const data = response.data.map(({ userId, ...rest }) => ({ ...rest, ...userId, uid: rest?._id, id: rest?._id }))
    console.log('data', data)
    setRowData(data)
    console.log(rowData, "ooo")
  }

  const handleResponsePres = (row) => {
    setOpenPres(true);
    dispatch(setPendingData(row));
  };


  const presColumn = {
    field: "Pending",
    width: 130,
    renderCell: (cellData) => (
      <PendingActionsIcon
        style={{ margin: "0 10px", cursor: "pointer" }}
        onClick={(e) => handleResponsePres(cellData.row)}
      >
        Action
      </PendingActionsIcon>
    ),
  };

  return (
    <>
      <AdminDashboard>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            className={classes.roots}
            style={{ width: "100%", borderRadius: "15px", padding: "10px", background: "white", boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)", marginTop: "50px", marginLeft: "-15px" }}
            columns={[presColumn,
              { field: "name", width: 180 },
              { field: "phoneNo", width: 180 },
              { field: "address", width: 200 },
              { field: "email", width: 200 },
              { field: "fname", width: 200 },
              { field: "fphoneNo", width: 200 },
              { field: "fmName", width: 200, headerName: "Family Member Name " },
              { field: "fmphoneNo", width: 200, headerName: "Family Member Phone#" },
              { field: "fmName1", width: 200, headerName: "Family Member Name " },
              { field: "fmphoneNo1", width: 200, headerName: "Family Member Phone#" },
            ]}
            rows={rowData.filter((vv) => vv.status === false && vv.societyName === info.societyName)}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    backgroundColor: 'white',
                    border: "black",
                    boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: 'center',
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "600px",
                    marginLeft: "150px",
                    height: "35px",
                    marginTop: "-60px"
                  }}
                >
                  <GridToolbar {...props} />
                </div>
              ),

            }}
            getRowClassName={rowClassName(classes)}

          />
        </div>


        <AddResponseDrawer
          open={openPres}
          close={() => setOpenPres(false)}
          reCall={()=>fetchDataApi()}
        />

      </AdminDashboard>
    </>
  );
};

export default Pending;
