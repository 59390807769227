import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../../firebase";
import {
  Drawer,
  Stack,
  Box,
  Typography,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
} from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { useSelector } from "react-redux";
import axios from "axios";

const ContentEdit = ({ close, reCall }) => {
  
  const [guardName, setGuardName] = useState("")
  const [guardPhone, setGuardPhone] = useState("")
  const [guardEmail,setGuardEmail] = useState("")
  const notHomeData = useSelector((state) => state.user.nHData);
  const documentId = notHomeData.id;
  const userToken = notHomeData.FCMtoken;
  const info = JSON.parse(localStorage.getItem("currentUser"));

  const updateApi = async () => {
    console.log(documentId)
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/nothome/update/${documentId}`, {
      guardEmail: guardEmail,
      guardPhone: guardPhone,
      guardName: guardName,
      dateAndTime: `from   ${notHomeData.from}   to   ${notHomeData.to}`,
      societyName: info.societyName
    })
    close(false)
    reCall();
    toast.success("Guard Assigned")

  }


  const handleProceed = () => {
    if (!guardName) {
      toast.error("Please fill in the Guard Name field.");
      return;
    }

    if (!guardPhone) {
      toast.error("Please fill in the Phone Number field.");
      return;
    }

    if (!guardEmail) {
      toast.error("Please fill in the Email field.");
      return;
    }

    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(guardPhone)) {
      toast.error("Invalid phone number. Please enter 11 digits.");
      return;
    }

    if (!isValidEmail(guardEmail)) {
      toast.error("Invalid email address. Please enter a valid email.");
      return;
    }

    if (documentId)
       {
      updateApi()
        }
       
    
    else {
    }
  };


  const sendPushNotification = async (userToken) => {

    try {
      
  
      const message = {
        notification: {
          title: "Guard Assigned",
          body: "Tap to check!",
        },
        to: userToken,
      };
  
      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization":"key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
         
        },
        body: JSON.stringify(message),
      });
  
      if (response.ok) {
        // console.log("Push notification sent successfully");
      } else {
        // console.error("Error sending push notification.");
      }
    } catch (error) {
      // console.error("Error sending push notification:", error);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  


  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Not at Home Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          variant="outlined"
          value={`from   ${notHomeData.from}   to   ${notHomeData.to}`}
          label="Duration"
          multiline
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />
        <TextField
          fullWidth
          variant="outlined"
          onChange={(e) => setGuardName(e.target.value)}
          label="Guard Name"
          multiline
          id="outlined-multiline-flexible"
          maxRows={5}
        />
        <TextField
          fullWidth
          variant="outlined"
          onChange={(e) => setGuardPhone(e.target.value)}
          type="number"
          label="Phone Number"
          id="outlined-multiline-flexible"
          inputProps={{ pattern: "[0-9]{11}" }}
          helperText="Please enter a valid 11-digit phone number."
        />
        <TextField
          fullWidth
          variant="outlined"
          onChange={(e) => setGuardEmail(e.target.value)}
          type="email"
          label="Email"
          multiline
          id="outlined-multiline-flexible"
          helperText="Please enter a valid email address."
        />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <SolidButton
                label="Proceed"
                onClick={() => {
                  handleProceed();
                  sendPushNotification(userToken);

                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function AddNotHomeDrawer({ open, close, reCall }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
      
        <ContentEdit close={close} reCall={reCall}
/>
      </Drawer>
    
    </React.Fragment>
  );
}

export default AddNotHomeDrawer;
