import React, { useState, useEffect } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../../firebase";
import { oldDocuments,getCountOfNewDocuments } from "../function";

const SecurityNoti = ({ open, close ,setNewDocumentCountSec}) => {
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const [securityData, setSecurityData] = useState([]);
  const [newDocumentsCountSec, setNewDocumentsCountSec] = useState("");


  useEffect(() => {
    // Get false document counts
    getCountOfNewDocuments("button-one", setNewDocumentsCountSec);
    }, [
    newDocumentsCountSec,
  ]);

  const handleOldDocuments=(collectionName)=>{
  oldDocuments(collectionName)
  if(collectionName==="button-one"){
    close(false);
    setNewDocumentCountSec("")

    alert("error")
  }


  }
  // Use useEffect to fetch all documents from the "complian" collection when the component mounts
 useEffect(() => {
   const unsubscribe = configapp
     .firestore()
     .collection(info.SubRole)
     .orderBy("pressedTime", "desc")

     .onSnapshot(
       (querySnapshot) => {
         const data = [];
         querySnapshot
           .forEach((doc) => {
             data.push(doc.data());
           })
           .filter(
             (val) => val.noti === true && val.societyName === info.societyName
           );
         setSecurityData(data);
       },
       (error) => {
         console.error("Error getting documents:", error);
       }
     );

   // Return the unsubscribe function in case you need to unsubscribe later
   return () => unsubscribe();
 }, [info.SubRole]);


  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
            // marginTop: "60px",
          },
        }}
        anchor="right"
        open={open}
        onClose={() =>{ 
          close(false);

        }
        }
      >
        <Box sx={{ padding: "2rem" }}>
          <ToastContainer />
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
            >
              Security Details
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() =>{
              handleOldDocuments("button-one")
          // window.location.reload()
          }}>
              <CloseIcon />
            </Box>
          </Box>

          <Box>
          {securityData.length === 0 && (
            <Typography variant="body1">No more new requests</Typography>
          )}
            {securityData.length > 0 && securityData.map((securityDoc, index) => (
              <div key={index}>
                {/* Display the details of each document */}
                <Typography variant="h6">Security {index + 1}:</Typography>
               
                {/* <Typography variant="body1">
                <strong>Security:</strong> {securityDoc.Complain}
                </Typography> */}

                <Typography variant="body1">
                  <strong>Name:</strong> {securityDoc.name}
                </Typography>

                <Typography variant="body1">
                  <strong>PhoneNo:</strong> {securityDoc.phoneNo}
                </Typography>

                <Typography variant="body1">
                  <strong>Address:</strong> {securityDoc.address}
                </Typography>

                <Typography variant="body1">
                  <strong>Date:</strong> {securityDoc.date}
                </Typography>

                <Typography variant="body1">
                  <strong>Time:</strong> {securityDoc.time}
                </Typography>
                {/* <Typography variant="body1">
                  <strong>Date & Time:</strong> {securityDoc.pressedTime}
                </Typography> */}
                <hr />
              </div>
            ))}
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default SecurityNoti;
