import { Redirect, Route , BrowserRouter} from "react-router-dom";

const Private = ({ component: Componenet, ...props }) => {
  let userdetail = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : "";
  return (
    <>
      <Route
        render={(props) =>
          userdetail?.name ? (
            <Componenet {...props} />
          ) : (
            <Redirect to="/Login" />
          )
        }
      />
    </>
  );
};

export default Private;
