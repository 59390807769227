import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { configapp } from "../../../firebase";
import AdminDashboard from "../Dashboard";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';

const NotificationPanel = (props) => {
  const [textValue, setTextValue] = useState("");
  const [title,setTitle]=useState("");
  const [body,setBody]=useState("");

  const [residentData, setResidentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFetchAll, setLoadingFetchAll] = useState(false);

  const db = configapp.firestore();

  const notifyError = () => {
    toast.error("No matching document found.")
  };

  const notify = (message) => {
    toast.success(message);
  };

  const sendPushNotification = async (userToken) => {
    try {
      const message = {
        notification: {
          title: title,
          body: body,
          imageUrl: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",

        },
       
        to: userToken,
      };

      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("Push notification sent successfully");
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

const handleFind = () => {
  setIsLoading(true);

  const name = textValue.trim();
  const collection = db.collection("UserRequest");

  const unsubscribe = collection.where("Name", "==", name).onSnapshot(
    (querySnapshot) => {
      if (querySnapshot.size === 0) {
        setResidentData(null);
        notifyError();
      } else {
        const documentData = querySnapshot.docs[0].data();
        const selectedFields = {
          Name: documentData.Name,
          House_no: documentData.address,
          TFM: documentData.TFM,
          Phone_Number: documentData.phonenumber,
          Father_Name: documentData.fName,
          Father_Phone_number: documentData.fPhonenumber,
        };
        setResidentData(selectedFields);
      }
      setIsLoading(false);
    },
    (error) => {
      console.error("Error searching for resident data:", error);
      setIsLoading(false);
    }
  );
  return () => unsubscribe();
};


const fetchAllAndSendNotifications = () => {
  setLoadingFetchAll(true);

  const collection = db.collection("UserRequest");

  const unsubscribe = collection.onSnapshot(
    (querySnapshot) => {
      if (querySnapshot.size === 0) {
        notifyError();
      } else {
        querySnapshot.forEach((doc) => {
          const userToken = doc.data().FCMtoken;
          if (userToken) {
            sendPushNotification(userToken);
          }
        });
        notify("Push notifications sent to all users successfully.");
      }
      setLoadingFetchAll(false);
    },
    (error) => {
      console.error(
        "Error fetching FCM tokens and sending push notifications:",
        error
      );
      setLoadingFetchAll(false);
    }
  );

  // Return the unsubscribe function in case you need to unsubscribe later
  return () => unsubscribe();
};



 const handleFetchAndSend = () => {
   try {
     const name = textValue.trim();
     const collection = db.collection("UserRequest");

     const unsubscribe = collection.where("Name", "==", name).onSnapshot(
       (querySnapshot) => {
         if (querySnapshot.size === 0) {
           notifyError();
         } else {
           const documentData = querySnapshot.docs[0].data();
           const userToken = documentData.FCMtoken;

           if (userToken) {
             sendPushNotification(userToken);
             notify("Push notification sent successfully.");
           } else {
             notifyError();
           }
         }
       },
       (error) => {
         console.error(
           "Error fetching FCM token and sending push notification:",
           error
         );
       }
     );

     // Return the unsubscribe function in case you need to unsubscribe later
     return () => unsubscribe();
   } catch (error) {
     console.error(
       "Error fetching FCM token and sending push notification:",
       error
     );
   }
 };

  return (
    <AdminDashboard>
 <Stack 
 
 >

<h3>Notification:</h3>


<TextField
placeholder="Title"
value={title}
style={{width:"35%",marginBottom:"10px"}}
onChange={(e) => setTitle(e.target.value)}
/>
<TextField
placeholder="Body"
value={body}
style={{width:"35%",marginBottom:"10px"}}

onChange={(e) => setBody(e.target.value)}
/>

{/* <label>
     <input
         type="file"
        accept="image/*,video/*"
      style={{ display: 'none' }}
//   onChange={handleMediaInputChange}
                  multiple
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Upload Media"
                    style={{ width: '180px', marginBottom: '10px' }}
                    
                    disabled
                  />
                  <div>
                    <label>
                      <WebAssetIcon
                        label="Browse Media"
                        fontSize="large"
                        cursor="pointer"
                      />
                    </label>
                  </div>
                </div>
              </label> */}
              <Button
          onClick={() => fetchAllAndSendNotifications()}
          style={{
            width: "150px",
            color: "white",
            backgroundColor: "#03136C",
            borderRadius: "10px",
            textAlign: "center",
          }}
          disabled={loadingFetchAll}
        >
          {loadingFetchAll ? "Fetching..." : "Send to All"}
        </Button>
        </Stack>
<div
style={{marginTop:"20px"}}
>
      <TextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        placeholder="Enter Full Name"
      />

      <div style={{ display: "flex", marginTop: "20px" }}>
        <Button
          onClick={() => handleFind()}
          style={{
            width: "90px",
            color: "white",
            backgroundColor: "#03136C",
            borderRadius: "10px",
            // textAlign: "center",
            marginRight: "10px",
          }}
          disabled={isLoading}
        >
          Verify
        </Button>

       

       
      </div>
     
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : residentData ? (
        <div style={{ marginTop: "10px" }}>
          <div>
            <h2 style={{ textAlign: "center" }}>Resident Data</h2>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>User</strong></TableCell>
                    <TableCell><strong>Value</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(residentData).map(([property, value]) => (
                    <TableRow key={property}>
                      <TableCell>{property}</TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
         <div
         style={{marginTop:"20px", textAlign:"center"}}
         >
          <Button
            style={{
                width: "100px",
                color: "white",
                backgroundColor: "#03136C",
                borderRadius: "10px",
                textAlign: "center",
              }}
          onClick={()=>{
            handleFetchAndSend()
          }}>Send </Button>
          </div>
        </div>
      ) : null}
    </AdminDashboard>
  );
};

export default NotificationPanel;
