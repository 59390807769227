import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { configapp } from "../../../firebase";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const PostsTable = () => {
  const [posts, setPosts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);

  const closeModal = () => {
    setShowModal(false);
    setPostIdToDelete(null);
  };

  const openModal = (postId) => {
    setPostIdToDelete(postId);
    setShowModal(true);
  };
  const info = JSON.parse(localStorage.getItem("currentUser"));

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(configapp);
      const feedCollectionRef = collection(db, "plots");
      const snapshot = await getDocs(feedCollectionRef);

      let postsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        mediaUrl: doc.data().mediaUrls[0],
        timestamp: doc.data().timestamp.toDate(),
        date: doc.data().date,
        time: doc.data().time,
        description: doc.data().description,
        societyName: doc.data().societyName, // Add this line to retrieve the societyName
      }));

      if (info.societyName && info.societyName !== "invoseg") {
        postsData = postsData.filter(
          (post) => post.societyName === info.societyName
        );
      }

      setPosts(postsData);
    };

    fetchData();
  }, [info]);

  const columns = [
    { field: "title", headerName: "Title", width: 100 },
    {
      field: "mediaUrl",
      headerName: "Media",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Media"
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    { field: "description", headerName: "Description", width: 200 },
    { field: "time", headerName: "Time", width: 150 },
    { field: "date", headerName: "Date", width: 150 },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => openModal(params.row.id)} color="secondary">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = async () => {
    try {
      const db = getFirestore(configapp);
      const feedCollectionRef = collection(db, "plots");
      const docRef = doc(feedCollectionRef, postIdToDelete);

      await deleteDoc(docRef);
      // Update the state to reflect the deleted post
      setPosts((prevPosts) =>
        prevPosts.filter((post) => post.id !== postIdToDelete)
      );
      // Show a success message or trigger a reload if needed
      toast.success("Post deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Error deleting post", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      closeModal();
    }
  };
  return (
    <div>
      <Modal
        style={{ marginTop: "100px" }}
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this post?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={posts}
          columns={columns}
          pageSize={5}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "white",
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "500px",
                  marginLeft: "50px",
                  height: "35px",
                  marginTop: "-60px",
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),
          }}
        />
      </div>

      <ToastContainer />
    </div>
  );
};

export default PostsTable;
