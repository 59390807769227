import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDoctorData(state, action) {
      state.doctorData = action.payload;
      console.log("Current user data:", state.doctorData);
    },
    setPrescriptionData(state, action) {
      state.prescriptionData = action.payload;
      console.log("Current user data:", state.prescriptionData);
    },
    setCompletedDoctorData(state, action) {
      state.completedDoctorData = action.payload;
      console.log("Current user data:", state.completedDoctorData);
    },
    setGroceryData(state, action) {
      state.groceryData = action.payload;
      console.log("Current user data:", state.groceryData);
    },
    setOrderData(state, action) {
      state.orderData = action.payload;
      console.log("Current user data:", state.orderData);
    },
    setCompletedGroceryData(state, action) {
      state.completedGroceryData = action.payload;
      console.log("Current user data:", state.completedGroceryData);
    },
    setComplainData(state, action) {
      state.complainData = action.payload;
      console.log("Current user data:", state.complainData);
    },
    setPendingData(state, action) {
      state.pendingData = action.payload;
      console.log("Current user data:", state.pendingData);
    },
    setCompletedData(state, action) {
      state.completedData = action.payload;
      console.log("Current user data:", state.completedData);
    },
    setSecurityData(state, action) {
      state.securityData = action.payload;
      console.log("Current user data:", state.securityData);
    },
    setProcessSecData(state, action) {
      state.processSecData = action.payload;
      console.log("Current user data:", state.processSecData);
    },
    setCompletedSecurityData(state, action) {
      state.completedSecurityData = action.payload;
      console.log("Current user data:", state.completedSecurityData);
    },
    setNHData(state, action) {
      state.nHData = action.payload;
      console.log("Current user data:", state.nHData);
    },
    setNHCancelledData(state, action) {
      state.nHCancelledData = action.payload;
      console.log("Current user data:", state.nHCancelledData);
    },
    setNHCompletedData(state, action) {
      state.nHCompletedData = action.payload;
      console.log("Current user data:", state.nHCompletedData);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setDoctorData, setLoading, setError,setPrescriptionData, setCompletedDoctorData, setGroceryData, setCompletedGroceryData , setOrderData ,setComplainData , setPendingData , setCompletedData , setSecurityData , setProcessSecData , setCompletedSecurityData, setNHCancelledData , setNHCompletedData , setNHData


} = userSlice.actions;




export default userSlice.reducer;
