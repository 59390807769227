import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  MenuItem,
  Stack
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import "firebase/compat/messaging";
import { configapp } from "../../../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const currencies = [
  {
    value: "Kilogram",
    label: "kg",
  },
  {
    value: "gram",
    label: "g",
  },
  {
    value: "miligram",
    label: "mg",
  },
  {
    value: "mililiter",
    label: "ml",
  },

  {
    value: "liter",
    label: "liter",
  },
  {
    value: "pound",
    label: "lb",
  },
  {
    value: "dozen",
    label: "dz",
  },
  {
    value: "pieces",
    label: "pcs",
  },
  {
    value: "boxes",
    label: "boxes",
  },
  {
    value: "packet",
    label: "pack",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ContentUpdate = ({ close, open, handleDeleteExistingItem, grocerydata, reCall }) => {
  const grocData = useSelector((state) => state.user.orderData);
  console.log(grocData)
  const docId = grocData.id

  const userToken = grocData.Token;
  const id = grocData.id;

  const info = JSON.parse(localStorage.getItem("currentUser"));


  const [isDataEnable, setIsDataEnable] = useState(true);
  const [isNewDataEnable, setIsNewDataEnable] = useState(false);
  const [items, setItems] = useState([
    { id: 1, item: "", quantity: "", currency: "", price: "", total: "" },
  ]);
  const [setGroceryItems] = useState([
    { id: 1, item: "", quantity: "", currency: "", price: "", total: "" },
  ]);


  const newDataToSave = {
    items: items?.map(
      (item) => ({
        itemName: item.item,
        quantity: item.quantity,
        currency: item.currency,
        price: item.price,
        total: item.total,
      })
    ),
  };

  const handleSave = () => {
    updateApi()
  };



  const updateApi = async () => {
    const responseData = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/orders/${id}`, {
      items: [...grocData.items, ...newDataToSave.items],
      allTotal: calculateTotal(),
      Status: "Delivered",
      update: true,
    })
    notiApi();
    reCall();
    close(false);

    console.log(responseData)
    console.log(newDataToSave.items, grocData.items)
  }




  const notiApi = async () => {
    const notificationData = {
      title: "GROCERY",
      description: `Order details have been updated`,
      image: "https://example.com/notification-image.jpg",
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      id: grocData.id,
      isRead: false,
      uid: grocData.uid,
      pressedTime: new Date(),
      societyName: info.societyName

    };

    addDoc(collection(getFirestore(), "notifications"), notificationData)
      .then(() => {
        console.log("Notification added successfully.");
        console.log(userToken, id, "pppppppppppppppppppppppppppp")
        sendPushNotification(userToken);

      })
      .catch((error) => {
        console.error("Error updating complainResponse:", error);
      });
  }

  const notify = () => toast.success("Successfully updated!");

  const handleAddNewItem = () => {
    const newItem = {
      id: items.length + 1,
      item: "",
      quantity: "",
      currency: "",
      price: "",
      total: "",
    };
    setItems([...items, newItem]);
  };

  const handleItemChange = (itemId, field, value) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        const updatedItem = { ...item, [field]: value };

        if (field === "quantity") {
          updatedItem[field] = Math.max(0, parseFloat(value) || 0);
        }
        if (field === "price") {
          updatedItem[field] = parseFloat(value) || 0;
        }
        if (field === "quantity" || field === "price") {
          updatedItem.total =
            (updatedItem.quantity || 0) * (updatedItem.price || 0);
        }

        return updatedItem;
      }
      return item;
    });
    setItems(updatedItems);
  };

  const handleGroceryItemChange = (index, field, value) => {
    const updatedItems = [...grocerydata.items];
    const updatedItem = updatedItems[index];

    updatedItem[field] = value;

    setGroceryItems(updatedItems);
  };
  const calculateTotal = () => {
    const sumOfExistingTotals = grocData?.items.reduce(
      (total, item) => total + (item.quantity * item.price || 0),
      0
    );
    const sumOfGroceryDataTotals = items.reduce(
      (total, item) => total + (item.total || 0),
      0
    );
    return sumOfExistingTotals + sumOfGroceryDataTotals + 150;
  };

  const handleDataDisable = () => {
    setIsDataEnable(false);
  };
  const handleNewDataEnable = () => {
    setIsNewDataEnable(true);
  };

  



  const sendPushNotification = async (userToken, id) => {
    try {
      const message = {
        notification: {
          title: "Order Updated",
          body: "Tap to check!",
        },
        to: userToken,
        data: {
          documentId: id,
        },
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("Push notification sent successfully");
      } else {
        // console.error("Error sending push notification.");
      }
    } catch (error) {
      // console.error("Error sending push notification:", error);
    }
  };
  return (
    <>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "45%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3,
          },
        }}
        anchor="right"
        open={open}
        onClose={close}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              fontSize: 30,
              fontWeight: 400,
              fontFamily: "Rooboto",
              paddingLeft: "15px",
            }}
          >
            Grocery Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-35px",
            }}
          >
            <CloseIcon sx={{ cursor: "pointer" }} onClick={close} />
          </Box>
        </CardContent>

        {isDataEnable && (
          <>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "40px", padding: "10px" }}
            >
              <Table sx={{ minWidth: 400 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Items</StyledTableCell>
                    <StyledTableCell align="right">Quantity</StyledTableCell>
                    <StyledTableCell align="right">Currency</StyledTableCell>
                    <StyledTableCell align="right">Price</StyledTableCell>
                    <StyledTableCell align="right">Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grocData?.items.map((item) => (
                    <StyledTableRow key={item.itemName}>
                      <StyledTableCell component="th" scope="row">
                        {item.itemName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.quantity}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.currency}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.price}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.total}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <SolidButton
                  label="Update"
                  onClick={() => {
                    handleDataDisable();
                    handleNewDataEnable();
                  }}
                />
              </div>
            </TableContainer>
            <div style={{ marginTop: "40px", textAlign: "center" }}>
              <SolidButton
                label="Save"
                onClick={() => {
                  updateApi()
                  handleDataDisable();
                  handleNewDataEnable();
                }}
              />
            </div>
          </>
        )}

        {isNewDataEnable && (
          <Box>
            <div
              style={{
                marginBottom: "10px",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <SolidButton label="Add New" onClick={handleAddNewItem} />
            </div>

            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "40px",
              }}
              style={{ marginLeft: "-45px" }}
            >
              {items?.map((item) => (
                <ul key={item.id} style={{ listStyle: "none" }}>
                  <li>
                    <TextField
                      multiline
                      id="outlined-basic"
                      label="Item"
                      variant="outlined"
                      style={{ width: "100px", padding: "5px" }}
                      value={item.itemName}
                      onChange={(e) =>
                        handleItemChange(item.id, "item", e.target.value)
                      }
                    />

                    <TextField
                      id="outlined"
                      label="Quantity"
                      variant="outlined"
                      type="number"
                      style={{ width: "85px", padding: "5px" }}
                      value={item.quantity}
                      onChange={(e) =>
                        handleItemChange(item.id, "quantity", e.target.value)
                      }
                    />

                    <TextField
                      id="outlined-select-currency"
                      variant="outlined"
                      select
                      style={{ width: "85px", padding: "5px" }}
                      value={item.currency}
                      onChange={(e) =>
                        handleItemChange(item.id, "currency", e.target.value)
                      }
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="outlined-basic"
                      multiline
                      label="Per Price"
                      type="number"
                      variant="outlined"
                      style={{ width: "90px", padding: "5px" }}
                      value={item.price}
                      onChange={(e) =>
                        handleItemChange(item.id, "price", e.target.value)
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      multiline
                      label="Total"
                      variant="outlined"
                      style={{ width: "80px", padding: "5px" }}
                      value={item.total || ""}
                      disabled
                    />
                  </li>
                </ul>
              ))}

              {!isDataEnable && isNewDataEnable && grocData && (
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: 30,
                      fontWeight: 400,
                      fontFamily: "Rooboto",
                      paddingLeft: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Existing Data
                  </Typography>
                  {grocData?.items.map((item, index) => (
                    <div key={index}>
                      <TextField
                        id={`item-${index}`}
                        multiline
                        label="Item"
                        variant="outlined"
                        style={{ width: "100px", padding: "5px" }}
                        value={item.itemName}
                        onChange={(e) =>
                          handleGroceryItemChange(
                            index,
                            "itemName",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        id={`quantity-${index}`}
                        label="Quantity"
                        variant="outlined"
                        style={{ width: "100px", padding: "5px" }}
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleGroceryItemChange(
                            index,
                            "quantity",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        id={`currency-${index}`}
                        variant="outlined"
                        style={{ width: "100px", padding: "5px" }}
                        select
                        value={item.currency}
                        onChange={(e) =>
                          handleGroceryItemChange(
                            index,
                            "currency",
                            e.target.value
                          )
                        }
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        id={`price-${index}`}
                        label="Per Price"
                        variant="outlined"
                        style={{ width: "100px", padding: "5px" }}
                        type="number"
                        value={item.price}
                        onChange={(e) =>
                          handleGroceryItemChange(
                            index,
                            "price",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        id={`total-${index}`}
                        label="Total"
                        variant="outlined"
                        style={{ width: "100px", padding: "5px" }}
                        value={item.quantity * item.price}
                        disabled
                      />

                      <DeleteOutlineIcon
                        label="Delete"
                        fontSize="medium"
                        sx={{ marginTop: "16px", cursor: "pointer" }}
                        onClick={() => handleDeleteExistingItem(index)}
                      />
                    </div>
                  ))}
                </Box>
              )}

              <div
                style={{
                  marginBottom: "75px",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <Stack>
                  <Typography variant="body1">
                    Delivery Charges: 150Rs
                  </Typography>
                  <Typography variant="h5">
                    Total: {calculateTotal()}
                  </Typography>
                </Stack>
                <SolidButton
                  label="Save"
                  onClick={() => {
                    handleSave();
                    close(false);
                  }}
                />
              </div>
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
};


function AddGroceryDrawerUpdate({
  open,
  close,
  reCall
}) {
  // console.log(id,"uuuuuuuuuu")

  const [isLoading, setIsLoading] = useState(true);
  const [grocerydata, setGroceryData] = useState(null);
  const grocData = useSelector((state) => state.user.orderData);

  const handleDeleteExistingItem = (index) => {
    const updatedItems = [...grocerydata.items];
    const itemToDelete = updatedItems[index];

    configapp
      .firestore()
      .collection("grocery")
      .doc(grocData.id)
      // .limit(30)

      .update({
        items: firebase.firestore.FieldValue.arrayRemove(itemToDelete),
      })
      .then(() => { })
      .catch((error) => {
        console.error("Error deleting item from Firebase: ", error);
      });

    updatedItems.splice(index, 1);
    setGroceryData((prevData) => ({
      ...prevData,
      items: updatedItems,
    }));
  };
  useEffect(() => {
    if (open) {
      const db = firebase.firestore();
      const groceryRef = db.collection("grocery").doc(grocData.id);

      const unsubscribe = groceryRef.onSnapshot(
        (doc) => {
          if (doc.exists) {
            setGroceryData(doc.data());
            setIsLoading(false);
          } else {
            // console.log("No such document!");
          }
        },
        (error) => {
          // console.log("Error getting document:", error);
          setIsLoading(false);
        }
      );

      // Return the unsubscribe function in case you need to unsubscribe later
      return () => unsubscribe();
    }
  }, [open,]);

  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "45%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3,
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >

        <ContentUpdate
          close={() => close(false)}
          grocerydata={grocerydata}
          open={open}
          handleDeleteExistingItem={handleDeleteExistingItem}
          reCall={reCall}
        />
      </Drawer>
    </React.Fragment>
  );
}

export default AddGroceryDrawerUpdate;
