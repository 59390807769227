import { getDocs, where, query, collection, doc, updateDoc } from "firebase/firestore";
import { configapp } from "../../firebase";

export const getCountOfFalseDocuments = async (collectionName, setFalse) => {
  // try {
  //   const collectionRef = collection(configapp.firestore(), collectionName);
  //   const q = query(collectionRef, where("count", "==", false));
  //   const querySnapshot = await getDocs(q);

  //   const count = querySnapshot.size;
  //   setFalse(count);
  // } catch (error) {
  //   console.error("Error getting false documents:", error);
  //   setFalse(0);
  // }
};


export const getCountOfNewDocuments = async (collectionName, setNew) => {
  // try {
  //   const collectionRef = collection(configapp.firestore(), collectionName);
  //   const q = query(collectionRef, where("noti", "==", true));
  //   const querySnapshot = await getDocs(q);

  //   const count = querySnapshot.size;
  //   setNew(count);
  // } catch (error) {
  //   console.error("Error getting false documents:", error);
  //   setNew(0);
  // }
};


export const trueDocuments = async (collectionName) => {
 
  // const collectionRef = collection(configapp.firestore(), collectionName);
  // const q = query(collectionRef, where("count", "==", false));
  // const querySnapshot = await getDocs(q);

  // querySnapshot.forEach(async (docSnapshot) => {
  //   // console.log("Collection Reference:", collectionRef.path);
  //   // console.log("Document ID:", docSnapshot.id);

  //   // Correct usage of doc function to create a reference
  //   const docRef = doc(collectionRef, docSnapshot.id);

  //   // Use await here to ensure the update completes before continuing
  //   await updateDoc(docRef, { count: true });
  // });

  // console.log("All documents updated to count=true");
};



export const oldDocuments = async (collectionName) => {
  
  // const collectionRef = collection(configapp.firestore(), collectionName);
  // const q = query(collectionRef, where("noti", "==", true));
  // const querySnapshot = await getDocs(q);

  // querySnapshot.forEach(async (docSnapshot) => {
  //   // console.log("Collection Reference:", collectionRef.path);
  //   // console.log("Document ID:", docSnapshot.id);

  //   // Correct usage of doc function to create a reference
  //   const docRef = doc(collectionRef, docSnapshot.id);

  //   // Use await here to ensure the update completes before continuing
  //   await updateDoc(docRef, { noti: false });
  // });

  // console.log("All documents updated to count=true");
};

export const getTestOfFalseDocuments = async (collectionName) => {
//   try {
//     const collectionRef = collection(configapp.firestore(), collectionName);
//     const q = query(collectionRef, where("count", "==", false));
//     const querySnapshot = await getDocs(q);

//     const count = querySnapshot.size;
//     return count;
//   } catch (error) {

//     console.error("Error getting false documents:", error);
// return 0;  }
};

export const getTestOfNewDocuments = async (collectionName) => {
  // try {
  //   const collectionRef = collection(configapp.firestore(), collectionName);
  //   const q = query(collectionRef, where("noti", "==", true));
  //   const querySnapshot = await getDocs(q);

  //   const count = querySnapshot.size;
  //   return count;
  // } catch (error) {
  //   console.error("Error getting false documents:", error);
  //   return 0;
  // }
};

