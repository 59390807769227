import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Drawer, Stack, Box, Typography, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@material-ui/core";
import { SolidButton } from "../../Reuse/SolidButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const currencies = [
  { value: "Kilogram", label: "kg" },
  { value: "gram", label: "g" },
  { value: "miligram", label: "mg" },
  { value: "mililiter", label: "ml" },
  { value: "liter", label: "liter" },
  { value: "pound", label: "lb" },
  { value: "dozen", label: "dz" },
  { value: "pieces", label: "pcs" },
  { value: "boxes", label: "boxes" },
  { value: "packet", label: "pack" },
];

const ContentEdit = ({ close, reCall }) => {
  const [items, setItems] = useState([{ id: 1, item: "", quantity: "", currency: "", price: "" }]);
  const [documentId, setDocumentId] = useState("");
  const grocData = useSelector((state) => state.user.groceryData);
  const userToken = grocData.FCMtoken;
  const info = JSON.parse(localStorage.getItem("currentUser"));
  var docId = grocData.id;

  const notiApi = async () => {
    try {
      const notificationData = {
        title: "GROCERY",
        description: `Order has been placed`,
        image: "https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-test-icon-63758263.jpg",
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        id: grocData.id,
        isRead: false,
        uid: grocData.uid,
        pressedTime: new Date(),
        societyName: info.societyName
      };

      await addDoc(collection(getFirestore(), "notifications"), notificationData);
      console.log("Notification added successfully.");
      sendPushNotification(userToken);
      reCall();
    } catch (error) {
      console.error("Error updating complainResponse:", error);
      notifyError();
    }
  };

  const updateAndAddApi = async () => {
    try {
      const groceryData = items.map((item) => ({
        itemName: item.item,
        quantity: item.quantity,
        currency: item.currency,
        price: item.price,
        total: item.total,
      }));

      console.log("Grocery Data:", groceryData);

      const addDataResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/orders/`, {
        items: groceryData,
        uid: grocData.uid,
        allTotal: calculateTotal(),
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        pressedTime: new Date(),
        customerName: grocData.name,
        customerPhone: grocData.phoneNo,
        customerAddress: grocData.address,
        customerEmail: grocData.email,
        count: false,
        Status: "Processing",
        Token: grocData.FCMtoken,
        update: false,
        FMname: grocData.fmName,
        FMphone: grocData.fmphoneNo,
        FMname1: grocData.fmName1,
        FMphone1: grocData.fmphoneNo1,
        Fname: grocData.fname,
        Fphone: grocData.fPhoneNo,
        societyName: info.societyName,
      });

      console.log("Add Data Response:", addDataResponse.data);

      const updateResponse = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/grocery/${docId}`, {
        edit: true,
      });

      console.log("Update Response:", updateResponse.data);

      notiApi();
      close(false);
    } catch (error) {
      console.error("An error occurred:", error);
      if (error.response) {
        console.error("Error Data:", error.response.data);
        console.error("Error Status:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
      toast.error("An error occurred while processing your request.");
    }

  };

  useEffect(() => {
    console.log(grocData);
  }, [grocData]);

  const notify = () => toast.success("Successfully updated!");
  const notifyError = () => toast.error("Please fill in the required fields");

  const handleAddNewItem = () => {
    const newItem = { id: items.length + 1, item: "", quantity: "", currency: "", price: "" };
    setItems([...items, newItem]);
  };

  const handleItemChange = (itemId, field, value) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        const updatedItem = { ...item, [field]: value };

        if (field === "quantity" || field === "price") {
          const stringValue = String(updatedItem[field]).trim();
          let numericValue = parseFloat(stringValue);

          if (isNaN(numericValue) || numericValue < 0) {
            numericValue = 0;
          }

          updatedItem[field] = numericValue;

          if (!isNaN(updatedItem.quantity) && !isNaN(updatedItem.price)) {
            updatedItem.total = updatedItem.quantity * updatedItem.price;
          } else {
            updatedItem.total = undefined;
          }
        }
        return updatedItem;
      }
      return item;
    });
    setItems(updatedItems);
  };

  const calculateTotal = () => {
    const sumOfTotals = items.reduce((total, item) => total + (item.total || 0), 0);
    return sumOfTotals + 150;
  };

  const sendPushNotification = async (userToken, id) => {
    try {
      const message = {
        notification: {
          title: "Order Completed",
          body: "Tap to check!",
        },
        to: userToken,
        data: {
          documentId: id,
        },
      };

      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("PUSH SUCCESS");
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "3rem" }}>
        <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}>
          Grocery List Items
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <div style={{ marginBottom: "18px" }}>
        <SolidButton label="Add New" onClick={handleAddNewItem} />
      </div>
      <Box component="div" sx={{ display: "flex", flexDirection: "column" }} style={{ marginLeft: "-45px" }}>
        {items.map((item) => (
          <ul key={item.id} style={{ listStyle: "none" }}>
            <li>
              <TextField
                multiline
                id="outlined-basic"
                label="Item"
                variant="outlined"
                style={{ width: "100px", padding: "5px" }}
                value={item.item}
                onChange={(e) => handleItemChange(item.id, "item", e.target.value)}
              />
              <TextField
                id="outlined"
                label="Quantity"
                variant="outlined"
                type="number"
                style={{ width: "85px", padding: "5px" }}
                value={item.quantity}
                onChange={(e) => handleItemChange(item.id, "quantity", e.target.value)}
              />
              <TextField
                id="outlined-select-currency"
                variant="outlined"
                select
                style={{ width: "85px", padding: "5px" }}
                value={item.currency}
                onChange={(e) => handleItemChange(item.id, "currency", e.target.value)}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-basic"
                multiline
                label="Per Price"
                type="number"
                variant="outlined"
                style={{ width: "90px", padding: "5px" }}
                value={item.price}
                onChange={(e) => handleItemChange(item.id, "price", e.target.value)}
              />
              <TextField
                id="outlined-basic"
                multiline
                label="Total"
                variant="outlined"
                style={{ width: "80px", padding: "5px" }}
                value={item.total || ""}
                disabled
              />
            </li>
          </ul>
        ))}
        <div style={{ marginBottom: "45px", textAlign: "right" }}>
          <Stack>
            <Typography variant="body1">Delivery Charges: 150Rs</Typography>
            <Typography variant="h5">Total: {calculateTotal()}</Typography>
          </Stack>
          <SolidButton
            label="Save"
            onClick={() => updateAndAddApi()}
          />
        </div>
      </Box>
    </Box>
  );
};

function AddGroceryDrawerEdit({ open, close, reCall }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius: "10px",
            boxShadow: 3,
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentEdit close={close} open={open} reCall={reCall} />
      </Drawer>
    </React.Fragment>
  );
}

export default AddGroceryDrawerEdit;
