import { configapp, stis } from "../../../firebase";
import AdminDashboard from "../Dashboard";
import React, { useRef, useState } from "react";
import { TextField, Box, CircularProgress } from "@mui/material";
import { Button, Stack } from "react-bootstrap";
import { SolidButton } from "../../Reuse/SolidButton";
import { Icon } from "@iconify/react";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadIcon from "@mui/icons-material/Upload";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { Typography } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import PostsTable from "./Post";

const Plots = (props) => {
  const mediaInputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState([]);
  const [mediaPath, setMediaPath] = useState("");
  const [description, setDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [mediaSequence, setMediaSequence] = React.useState([]);
  const [showPost, setShowPost] = useState(true);
  const [showCms, setShowCms] = useState(false);
  const info = JSON.parse(localStorage.getItem("currentUser"));

  const showAllPost = () => {
    setShowPost(true);
  };
  const hideAllPost = () => {
    setShowPost(false);
  };

  const showCMS = () => {
    setShowCms(true);
    setShowPost(false);
  };
  const hideCMS = () => {
    setShowCms(false);
  };

  function createData(name, calories) {
    return { name, calories };
  }

  const listStyle = {
    display: "inline-block",
    marginRight: "40px",
    marginLeft: "-40px",
  };

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {},
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const handleMediaInputChange = async () => {
    const files = mediaInputRef.current.files;

    if (files && files.length > 0 && fileUrl.length < 5) {
      const selectedMedia = [...files];
      const mediaNames = selectedMedia.map((file) => file.name);
      const newFileUrls = [...fileUrl];

      setFileUrl([...fileUrl, ...mediaNames]);

      const mediaType = selectedMedia[0].type.startsWith("image")
        ? "image"
        : "video";
      const filesUrls = selectedMedia.map((file) => URL.createObjectURL(file));

      newFileUrls.push(...filesUrls);

      setMediaSequence((prevSequence) => [
        ...prevSequence,
        ...mediaNames.map((name) => ({ type: mediaType, path: name })),
      ]);
      setFileUrl(newFileUrls);
    } else {
      notifyError("You cannot upload more than 6 media files.");
    }
  };

  const handleRemoveMedia = (index) => {
    const updatedMedia = [...fileUrl];
    updatedMedia.splice(index, 1);
    setFileUrl(updatedMedia);

    const updatedSequence = [...mediaSequence];
    updatedSequence.splice(index, 1);
    setMediaSequence(updatedSequence);
    setMediaPath("");
  };

  const sendPushNotification = async (userToken, documId) => {
    try {
      const message = {
        notification: {
          title: "INVOSEG",
          body: "INVOSEG added new plot",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",
        },
        data: {
          documentId: documId,
        },
        // data: {
        //     image: "https://firebasestorage.googleapis.com/v0/b/usman-a51d1.appspot.com/o/Notifications%20Media%2Fnature-landscape-hd-usqznq19dscdjkf8.jpg?alt=media&token=cae6b7d6-f8cf-4224-af1c-72e48e93cc09",
        //   },

        to: userToken,
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("Push notification sent successfully", message);
        reload();
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };
  const db = configapp.firestore();

  const fetchAllAndSendNotifications = (documId) => {
    // setLoadingFetchAll(true);
    const collection = db.collection("UserRequest");

    const unsubscribe = collection.onSnapshot((querySnapshot) => {
      try {
        if (querySnapshot.size === 0) {
          notifyError();
        } else {
          querySnapshot.forEach((doc) => {
            const userToken = doc.data().FCMtoken;
            if (userToken) {
              sendPushNotification(userToken, documId);
            }
          });
          // notify("Push notifications sent to all users successfully.");
        }
      } catch (error) {
        console.error(
          "Error fetching FCM tokens and sending push notifications:",
          error
        );
      } finally {
        // setLoadingFetchAll(false);
      }
    });

    // Return the unsubscribe function in case you need to unsubscribe later
    return () => unsubscribe();
  };

  const uploadFile = async (file, fileType) => {
    const storageRef = ref(stis, `${fileType}/${file.name}`);
    await uploadBytes(storageRef, file);

    const downloadUrl = await getDownloadURL(storageRef);

    return downloadUrl;
  };

  const handleUploadClick = async () => {
    if (
      fileUrl.length > 5 ||
      fileUrl.length < 1 ||
      description.trim() === "" ||
      room === "Select Rooms" ||
      bath === "Select Baths" ||
      area === "Select Area" ||
      type === "Select Type" ||
      selectedCategory === "Select Payment" ||
      price.trim() === "" ||
      address.trim() === "" ||
      phone.trim() === "" ||
      parseFloat(price) <= 0 ||
      fileUrl.length === 0
    ) {
      notifyError("Please fill out all required fields");
      return;
    } else if (phone.trim().length !== 11) {
      notifyError("Please add 11 digit phone number");
      return;
    }

    setIsUploading(true);

    try {
      const fileUrlUrls = await Promise.all(
        fileUrl.map(async (mediaName) => {
          const mediaUrl = await uploadFile(
            mediaInputRef.current.files[fileUrl.indexOf(mediaName)],
            "invoseg"
          );

          return mediaUrl;
        })
      );

      const db = getFirestore(configapp);
      const feedCollectionRef = collection(db, "plots");

      const docRef = await addDoc(feedCollectionRef, {
        description: description,
        address: address,
        type: type,
        area: area,
        bath: bath,
        room: room,
        price: price,
        phone: phone,
        category: selectedCategory,
        mediaUrls: fileUrlUrls,
        timestamp: Timestamp.fromDate(new Date()),
        title: info.societyName,
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        societyName: info.societyName,
      });

      const documentId = docRef.id;

      if (documentId) {
        const feedDocRef = doc(feedCollectionRef, documentId);

        await updateDoc(feedDocRef, {
          description: description,
          address: address,
          type: type,
          area: area,
          bath: bath,
          room: room,
          price: price,
          phone: phone,
          category: selectedCategory,
          mediaUrls: fileUrlUrls,
          timestamp: Timestamp.fromDate(new Date()),
          id: documentId,
          title: info.societyName,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          societyName: info.societyName,
        });

        var documId = documentId;
        console.log(documId, "asasas");
        fetchAllAndSendNotifications(documentId, documId);
      }
      // window.location.reload();

      // setDescription('');
      // setAddress('');
      // setArea('Select Area');
      // setBath('Select Baths');
      // setRoom('Select Rooms');
      // setType('Select Type');
      // setSelectedCategory('Select Payment');
      // setMediaPath('');
      // setPrice('');
      // setMediaSequence([]);
      // setFileUrl([]);
      setIsUploading(false);
    } catch (error) {
      notifyError("Error uploading files or saving data to Firestore.");
      // console.error(error);
      setIsUploading(false);
    }
  };

  const time = new Date().toLocaleTimeString();

  const [type, setType] = useState("Select Type");
  const [area, setArea] = useState("Select Area");
  const [bath, setBath] = useState("Select Baths");
  const [room, setRoom] = useState("Select Rooms");
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Select Payment");

  const rows = [
    //   createData('Type', "Office"),
    //   createData('Area', "5 Marla"),
    //  createData('Bedroom(s) ', 4),
    //   createData('Bath(s) ', 4),
    //   createData('Price', 440000),
    //   createData('Location '," PIA"),

    createData("Type", `${type}`),
    createData("Area", `${area}`),
    createData("Bedroom(s) ", `${room}`),
    createData("Bath(s) ", `${bath}`),
    createData("Price", `${price}`),
    createData("Location ", `${address}`),
  ];
  const reload = async () => {
    await window.location.reload();
  };

  return (
    <AdminDashboard>
      <>
        {showCms && (
          <div style={{ display: "flex", width: "100%", height: "465px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "170px",
                marginTop: "5px",
                flex: 1,
                paddingRight: "20px",
              }}
            >
              <Box>
                <Stack>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    style={{ width: "180px", marginBottom: "10px" }}
                  >
                    <MenuItem value="Select Type">Select Type</MenuItem>

                    <MenuItem value="House">House</MenuItem>
                    <MenuItem value="Flat">Flat</MenuItem>
                    <MenuItem value="Upper Portion">Upper Portion</MenuItem>
                    <MenuItem value="Lower Portion">Lower Portion</MenuItem>
                    <MenuItem value="Farmhouse">Farmhouse</MenuItem>
                    <MenuItem value="Pent House">Pent House</MenuItem>
                    <MenuItem value="Pent House">Room</MenuItem>
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    onChange={(event) =>
                      setSelectedCategory(event.target.value)
                    }
                    style={{ width: "180px", marginBottom: "10px" }}
                  >
                    <MenuItem value="Select Payment">Select Payment</MenuItem>

                    <MenuItem value="Installments">Installments</MenuItem>
                    <MenuItem value="Full Pay">Full Pay</MenuItem>
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={area}
                    onChange={(event) => setArea(event.target.value)}
                    style={{ width: "180px", marginBottom: "10px" }}
                  >
                    <MenuItem value="Select Area">Select Area</MenuItem>

                    <MenuItem value="3 Marla">3 Marla</MenuItem>
                    <MenuItem value="5 Marla">5 Marla</MenuItem>
                    <MenuItem value="7 Marla">7 Marla</MenuItem>
                    <MenuItem value="8 Marla">8 Marla</MenuItem>
                    <MenuItem value="10 Marla">10 Marla</MenuItem>
                    <MenuItem value="1 Kanal">1 Kanal</MenuItem>
                    <MenuItem value="2 Kanal">2 Kanal</MenuItem>
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bath}
                    onChange={(event) => setBath(event.target.value)}
                    style={{ width: "180px", marginBottom: "10px" }}
                  >
                    <MenuItem value="Select Baths">Select Baths</MenuItem>

                    <MenuItem value="1 Bath">1 Bath</MenuItem>
                    <MenuItem value="2 Baths">2 Baths</MenuItem>
                    <MenuItem value="3 Baths">3 Baths</MenuItem>
                    <MenuItem value="4 Baths">4 Baths</MenuItem>
                    <MenuItem value="5 Baths">5 Baths</MenuItem>
                    <MenuItem value="6 Baths">6 Baths</MenuItem>
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={room}
                    placeholder="Select Number of Rooms"
                    onChange={(event) => setRoom(event.target.value)}
                    style={{ width: "180px", marginBottom: "10px" }}
                  >
                    <MenuItem value="Select Rooms">Select Rooms</MenuItem>

                    <MenuItem value="1 Room">1 Room</MenuItem>
                    <MenuItem value="2 Rooms">2 Rooms</MenuItem>
                    <MenuItem value="3 Rooms">3 Rooms</MenuItem>
                    <MenuItem value="4 Rooms">4 Rooms</MenuItem>
                    <MenuItem value="5 Rooms">5 Rooms</MenuItem>
                    <MenuItem value="6 Rooms">6 Rooms</MenuItem>
                  </Select>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter your Phone #"
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "/^-?d+(?:.d+)?$/g",
                    }}
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter your Description"
                    maxRows={5}
                    multiline
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Location"
                    maxRows={5}
                    multiline
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Price"
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "/^-?d+(?:.d+)?$/g",
                    }}
                    style={{ width: "300px", marginBottom: "10px" }}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />

                  <label style={{ width: "25%" }}>
                    <input
                      ref={mediaInputRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleMediaInputChange}
                      multiple
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Upload Media"
                        style={{ width: "300px", marginBottom: "10px" }}
                        onClick={() => mediaInputRef.current.click()}
                        value={mediaPath}
                        disabled
                      />
                      <div>
                        <label>
                          <WebAssetIcon
                            label="Browse Media"
                            fontSize="large"
                            cursor="pointer"
                            onClick={() => mediaInputRef.current.click()}
                          />
                        </label>
                      </div>
                    </div>
                  </label>
                </Stack>
              </Box>
              <Box>
                {isUploading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    label="Upload"
                    fontSize="large"
                    cursor="pointer"
                    onClick={() => {
                      handleUploadClick();
                      // fetchAllAndSendNotifications();
                    }}
                    style={{ width: "120px" }}
                  >
                    Upload
                  </Button>
                )}
              </Box>
              <Box style={{}}>
                <Typography style={{ marginTop: "20px" }}>
                  <strong>Media Preview:</strong>
                </Typography>
                <ul style={{ marginTop: "10px" }}>
                  {fileUrl.map((filesUrl, index) => (
                    <li key={index} style={listStyle}>
                      {mediaSequence[index].type === "image" && (
                        <img src={filesUrl} height="100px" width="100px" />
                      )}

                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveMedia(index)}
                      />
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
            <div
              style={{ height: "auto", width: "auto", position: "relative" }}
            >
              <img src="iphone.svg" width="100%" height="400px" />
              <div
                style={{
                  position: "absolute",
                  top: 9,
                  right: 22,
                  height: "4.5%",
                  width: "13%",
                  overflow: "hidden",
                  fontSize: "11px",
                }}
              >
                {/* <strong>{time}</strong> */}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 25,
                  left: 8.5,
                  height: "4%",
                  width: "92%",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    height: "100%",
                    width: "15%",
                  }}
                >
                  <img src="arrow.svg" width="100%" height="100%" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: 40,
                    height: "100%",
                    width: "70%",
                    fontSize: "13px",
                    marginTop: "3px",
                  }}
                >
                  <strong>Plots Details</strong>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    height: "100%",
                    width: "15%",
                  }}
                >
                  <img src="menu.svg" width="100%" height="100%" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 20,
                    height: "100%",
                    width: "15%",
                  }}
                >
                  <img src="bell.svg" width="100%" height="100%" />
                </div>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: 60,
                  left: 8,
                  height: "6.5rem",
                  width: "11.2rem",
                }}
              >
                <Slider {...settings}>
                  {fileUrl.map((filesUrl, index) => (
                    <li key={index}>
                      {mediaSequence[index].type === "image" && (
                        <img
                          src={filesUrl}
                          style={{
                            height: "6.5rem",
                            width: "11.2rem",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </li>
                  ))}
                </Slider>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 170,
                  left: 9.5,
                  width: "11.1rem",
                  height: "0.8rem",
                  fontSize: "10px",
                  padding: "3px",
                }}
              >
                <b>PKR:</b>
                {price}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 190,
                  left: 9.5,
                  width: "11.1rem",
                  height: "0.9rem",
                  fontSize: "10px",
                  padding: "3px",
                  // backgroundColor:"pink"
                }}
              >
                <b>{address}</b>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 210,
                  left: 9.5,
                  width: "11.1rem",
                  height: "1.5rem",
                  // backgroundColor:"yellow",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 7.5,
                    width: "3.1rem",
                    height: "1.5rem",
                    // backgroundColor:"pink",
                  }}
                >
                  <Icon icon="bxs:area" />
                  <p style={{ fontSize: "7px" }}>{area}</p>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 62.5,
                    width: "3.1rem",
                    height: "1.5rem",
                    // backgroundColor:"blue",
                  }}
                >
                  <Icon icon="material-symbols:bed" />
                  <p style={{ fontSize: "7px" }}>{room}</p>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 7.5,
                    width: "3.1rem",
                    height: "1.5rem",
                    // backgroundColor:"green",
                  }}
                >
                  <Icon icon="material-symbols:bathtub" />
                  <p style={{ fontSize: "7px" }}>{bath}</p>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 250,
                  left: 4,
                  // backgroundColor:"black",
                  width: "11.5rem",
                  height: "2rem",
                  display: "inline",
                  padding: "3px",
                }}
              >
                {/* <button
style={{
  width:"38px",
  height:"25px",
  border:"1px solid blue",
  borderRadius:"5px",
  padding:"2px",
  fontSize:"9px",
  marginLeft:"2px",
  marginRight:"2px",
  color:"blue"

  }}
>Email</button> */}
                <button
                  style={{
                    width: "35px",
                    height: "25px",
                    // border:"1px solid blue",
                    borderRadius: "5px",
                    padding: "2px",
                    fontSize: "9px",
                    marginRight: "30px",
                    float: "right",
                    backgroundColor: "rgb(0,43,61,1)",

                    color: "white",
                  }}
                >
                  SMS
                </button>
                <button
                  style={{
                    width: "40px",
                    height: "25px",
                    // border:"1px solid blue",
                    borderRadius: "5px",
                    padding: "2px",
                    fontSize: "9px",
                    marginLeft: "30px",
                    float: "left",
                    backgroundColor: "rgb(0,43,61,1)",
                    color: "white",
                  }}
                >
                  Call
                </button>

                {/* <button
style={{
  width:"55px",
  height:"25px",
  border:"1px solid blue",
  borderRadius:"5px",
  padding:"2px",
  fontSize:"9px",
  backgroundColor:"blue",
  color:"white"

}}
>Whatsapp</button> */}
              </div>
              {/* <div
          style={{
            position:"absolute",
            top:205,
            left:4,
            // backgroundColor:"black",
            width:"11.5rem",
            height:"2rem",
            display:"inline",
            padding:"3px"
          }}
          >
<button
style={{
  width:"38px",
  height:"25px",
  padding:"2px",
  fontSize:"7px",
  marginLeft:"2px",
  borderLeft:"none",
  borderRight:"none",
  borderTop:"none",
  borderBottom:"solid 2px blue",
  backgroundColor:"transparent"

  }}
>Overview</button>
<button
style={{
  width:"38px",
  height:"25px",
  
  fontSize:"7px",
  border:"none",
  backgroundColor:"transparent"

  

}}
>Nearby</button>
<button
style={{
  width:"50px",
  height:"25px",
  
  fontSize:"7px",
  border:"none",
  backgroundColor:"transparent"


  }}
>Home Finance</button>

<button
style={{
  width:"50px",
  height:"25px",
 padding:"2px",
 fontSize:"7px",
 border:"none",
 backgroundColor:"transparent"


}}
>Price Index</button>
            </div> */}
              {/* <div
style={{
  position:"absolute",
  top:235,
  left:8.5,
  width:"11.2rem",
  height:"4rem",
}}
>
<h6
style={{
  fontSize:"13px",
  marginTop:"3px",
  marginLeft:"5px"
}}
>Details</h6>
<div
style={{width:"100%" , height:"20px",marginTop:"-10px",
overflowX: "hidden",  // Set overflow-x to "hidden"
textOverflow: "ellipsis", 
 }}
>
<p
style={{
  fontSize:"8px",
  marginLeft:"5px"

}}
>{description}</p>
</div>
<div
style={{width:"100%" , height:"20px", marginTop:"15px"}}
>
<p
style={{
  fontSize:"8px",
  textAlign:"center",
  color:"blue",
  marginTop:"-8px"
}}
>Read Description</p>
</div>
</div> */}

              <div
                style={{
                  position: "absolute",
                  top: 280,
                  left: 0,
                  // backgroundColor:"pink",
                  width: "11.5rem",
                  height: "1rem",
                  fontSize: "10px", // padding:"3px",
                  textAlign: "center",
                  marginLeft: "5px",
                }}
              >
                Details
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 300,
                  left: 8,
                  width: "11.2rem",
                  height: "4.5rem",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{
                    height: "80px",
                    width: "135px",
                    marginLeft: "25px",
                    backgroundColor: "none",
                    boxShadow: "none",
                  }}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableHead></TableHead>

                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontSize: "5px",
                              height: "1px",
                              width: "20px",
                              borderBottom: "solid 1px black",
                              backgroundColor: "none",
                            }}
                          >
                            {row.name}
                          </TableCell>

                          <TableCell
                            align="right"
                            style={{
                              fontSize: "5px",
                              height: "1px",
                              width: "80px",
                              borderBottom: "solid 1px black",
                              backgroundColor: "none",
                            }}
                          >
                            <strong>{row.calories}</strong>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <ToastContainer />
          </div>
        )}
      </>
      <div style={{ marginTop: "40px", marginLeft: "25px" }}>
        {showPost && (
          <>
            <SolidButton
              label="Add Post"
              style={{ cursor: "pointer", marginTop: "-40px" }}
              onClick={showCMS}
            />
            <PostsTable />
          </>
        )}
      </div>
    </AdminDashboard>
  );
};

export default Plots;
