import React from "react";
import { Box } from "@mui/material";

const LoginLogo = () => {
  return (
    <Box>
        <Box component="img" src={"images/logo.jpg"} width={"150px"}  alt="logo" />
    </Box>
  );
};

export default LoginLogo;
