import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Drawer,
  Stack,
  Box,
  Typography,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";

const ContentEdit = ({ close}) => {

const notHomeData = useSelector((state) => state.user.nHData);

  return (
    <Box sx={{ padding: "2rem" }}>
      <ToastContainer />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
        >
          Not at Home Details
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => close(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          variant="outlined"
          value={`from   ${notHomeData.from}   to   ${notHomeData.to}`}
          label="Duration"
          multiline
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />
        <TextField
          fullWidth
          variant="outlined"
          value={notHomeData?.guardName}
          label="Guard Name"
          multiline
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />
        <TextField
          fullWidth
          variant="outlined"
          value={notHomeData?.guardPhone}
          label="Phone Number"
          multiline
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />
        <TextField
          fullWidth
          variant="outlined"
          value={notHomeData?.guardEmail}
          label="Email"
          multiline
          id="outlined-multiline-flexible"
          maxRows={5}
          disabled
        />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function AddNotHomeView({ open, close}) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
          },
        }}
        anchor="right"
        open={open}
        onClose={() => close(false)}
      >
        <ContentEdit close={close} 
  />
      </Drawer>
    </React.Fragment>
  );
}

export default AddNotHomeView;
