import React, { useState, useEffect } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../../firebase";
import { oldDocuments,getCountOfNewDocuments } from "../function";

const AddDoctorNoti = ({ open, close ,setNewDocumentCountNotHome}) => {
    
  const [securityData, setNotHomeData] = useState([]);
  const [newDocumentsCountGroNot, setNewDocumentsCountGroNot] = useState("");
  const info = JSON.parse(localStorage.getItem("currentUser"));


  useEffect(() => {
    // Get false document counts
    getCountOfNewDocuments("button-three", setNewDocumentsCountGroNot);
    
  }, [
    
    newDocumentsCountGroNot,
  ]);

  const handleOldDocuments=async(collectionName)=>{
   oldDocuments(collectionName)
  if(collectionName==="button-three"){
    close(false);
    setNewDocumentsCountGroNot("")

  }


  }
  // Use useEffect to fetch all documents from the "complian" collection when the component mounts
 useEffect(() => {
   const unsubscribe = configapp
     .firestore()
     .collection("button-three")
     .orderBy("pressedTime", "desc")

     .onSnapshot(
       (querySnapshot) => {
         const data = [];
         querySnapshot
           .forEach((doc) => {
             // Push each document's data into the 'data' array
             data.push(doc.data());
           })
           .filter(
             (val) => val.noti === true && val.societyName === info.societyName
           );
         setNotHomeData(data);
       },
       (error) => {
         console.error("Error getting documents:", error);
       }
     );

   // Return the unsubscribe function in case you need to unsubscribe later
   return () => unsubscribe();
 }, []);


  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
            position: "fixed",
            zIndex: "990",
            borderRadius:"10px",
boxShadow:3
            // marginTop: "60px",
          },
        }}
        anchor="right"
        open={open}
        onClose={() =>{ 
          close(false);

        }
        }
      >
        <Box sx={{ padding: "2rem" }}>
          <ToastContainer />
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 30, fontWeight: 400, fontFamily: "Roboto" }}
            >
               New Orders List
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() =>{
              handleOldDocuments("button-three")
          // window.location.reload()
          }}>
              <CloseIcon />
            </Box>
          </Box>

          <Box>
          {securityData.length === 0 && (
            <Typography variant="body1">No more new requests</Typography>
          )}
            {securityData.length > 0 && securityData.map((securityDoc, index) => (
              <div key={index}>
                {/* Display the details of each document */}
                <Typography variant="h6">Order {index + 1}:</Typography>
               
                <Typography variant="body1">
                  <strong>Name:</strong> {securityDoc.name}
                </Typography>

                <Typography variant="body1">
                  <strong>PhoneNo:</strong> {securityDoc.phoneNo}
                </Typography>

                <Typography variant="body1">
                  <strong>Address:</strong> {securityDoc.address}
                </Typography>

                <Typography variant="body1">
                  <strong>Email Address:</strong> {securityDoc.email}
                </Typography>

                <hr />
              </div>
            ))}
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default AddDoctorNoti;
