
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import { onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage'; 
import "firebase/compat/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCiHb1GzSeC19Qz3kSuL1i6o7M9soXSxmk",
  authDomain: "usman-a51d1.firebaseapp.com",
  projectId: "usman-a51d1",
  storageBucket: "usman-a51d1.appspot.com",
  messagingSenderId: "301480140457",
  appId: "1:301480140457:web:cac3702469d76963c613b8",
  measurementId: "G-CEEVDXJQ77"
};

const configapp = firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const stis = firebase.storage();
initializeApp(firebaseConfig);

const firebaseConfig1 = {
  apiKey: "AIzaSyD23Kr8eJoeJIPMGGnsDNuoahHuRBNyQMs",
  authDomain: "cms-all.firebaseapp.com",
  projectId: "cms-all",
  storageBucket: "cms-all.appspot.com",
  messagingSenderId: "338409219512",
  appId: "1:338409219512:web:6ee9084b4b425f50ac9b4d",
  measurementId: "G-1YY3MY06XV"
};
const configapp1 = initializeApp(firebaseConfig1, 'firebaseConfig1');
const stis1 = getStorage(configapp1);  

export { configapp, stis, configapp1, stis1 };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
