import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddPrescriptionDrawer from "./AddPrescriptionDrawer";

import { useDispatch} from "react-redux";
import { setPrescriptionData } from "../../store/slices/Userslice";
import { configapp } from "../../../firebase";

const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: "purple",
    fontWeight: "1800",
    borderRight: "none",
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: "none",
  },
}));

const Prescription = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [openPres, setOpenPres] = useState(false);
  const info = JSON.parse(localStorage.getItem("currentUser"));

    useEffect(() => {
      const unsubscribe = configapp
        .firestore()
        .collection("consultation")
        .orderBy("pressedTime", "desc")
        .limit(30)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs
            .map((doc) => ({
              ...doc.data(),
              pressedTime: new Date(doc.data()["pressedTime"].seconds * 1000),
              id: doc.id,
            }))
            .filter(
              (val) =>
                val.prescription === false &&
                val.societyName === info.societyName
            );

          setRowData(data);
        });

      return () => {
        unsubscribe();
      };
    }, [info.SubRole]);



  const handlePrescriptionPres = (row) => {
    dispatch(setPrescriptionData(row));   

    setOpenPres(true);
    console.log(row.doctor.meetingId);
    if (row.doctor.meetingId) {
      openJitsiMeetLink(row.doctor.meetingId);
      sendPushNotification(row.Token)
    }
  };

  const openJitsiMeetLink = (meetingId) => {
    const jitsiMeetLink = meetingId;
    window.open(jitsiMeetLink, "_blank");
  };



 const sendPushNotification = async (Token) => {
   try {
     const message = {
       notification: {
         title: "Doctor is ready for meeting",
         body: "Tap to join!",
       },
       to: Token,
     };

     const response = await fetch("https://fcm.googleapis.com/fcm/send", {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         Authorization:
           "key=AAAARjGd3qk:APA91bG6uaEYXOfBBFjc67brWG3sH59iHPcj7YpCytpuoNFE4srPCQWTkgWwray0MrlZ6e2asK3KJCuWsj78BQ5nHTs8PdgtvW53Krzz8TTReSeC0JbHJA0aWpoMwoh8hux1cDyjl0OR",
       },
       body: JSON.stringify(message),
     });

     if (response.ok) {
       // console.log("Push notification sent successfully");
     } else {
       // console.error("Error sending push notification.");
     }
   } catch (error) {
     // console.error("Error sending push notification:", error);
   }
 };


  const presColumn = {
    field: "Prescription",
    width: 160,
    renderCell: (cellData) => (
      <NoteAddIcon
        style={{ margin: "0 10px", cursor: "pointer" }}
        onClick={() => handlePrescriptionPres(cellData.row)}
      >
        Action
      </NoteAddIcon>
    ),
  };

  

  return (
    <AdminDashboard>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          className={classes.roots}
          style={{
            width: "100%",
            borderRadius: "15px",
            padding: "10px",
            background: "white",
            boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
            marginTop: "50px",
            marginLeft: "-15px",
          }}
          columns={[
            presColumn,

            {
              field: "patientName",
              headerName: " Name",
              valueGetter: (params) => params.row?.doctor?.patientName,
              width: 200,
            },
            {
              field: "patientPhone",
              headerName: " Phone",
              valueGetter: (params) => params.row?.doctor?.patientPhone,
              width: 200,
            },
            {
              field: "patientAddress",
              headerName: " Address",
              valueGetter: (params) => params.row?.doctor?.patientAddress,
              width: 250,
            },
            {
              field: "patientEmail",
              headerName: " Email",
              valueGetter: (params) => params.row?.doctor?.patientEmail,
              width: 250,
            },
            { field: "Fname", headerName: "Father Name", width: 200 },
            { field: "Fphone", headerName: "Father Phone", width: 200 },
            { field: "FMname", headerName: "Family Member Name", width: 200 },
            {
              field: "FMphone",
              headerName: "Family Member Phone",
              width: 200,
            },
            {
              field: "FMname1",
              headerName: "Family Member Name",
              width: 200,
            },
            {
              field: "FMphone1",
              headerName: "Family Member Phone",
              width: 200,
            },
            {
              field: "meetingId",
              headerName: "Meet Id",
              width: 200,
              valueGetter: (params) => params.row?.doctor?.meetingId,
            },
          ]}
          rows={rowData}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "white",
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "600px",
                  marginLeft: "150px",
                  height: "35px",
                  marginTop: "-60px",
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),
          }}
          getRowClassName={rowClassName(classes)}
        />
      </div>

      <AddPrescriptionDrawer open={openPres} close={() => setOpenPres(false)} />
    </AdminDashboard>
  );
};

export default Prescription;
